import { useRouter } from 'next/router'
import { MenuVendor } from '../../domain/menu-vendor'

type Props = {
  vendor: MenuVendor
  lang: string
}

export const useMenuCountryRedirect = ({ vendor, lang }: Props) => {
  const router = useRouter()
  const allowsCountry = vendor.locations.findIndex((location) => {
    const countryLang = location?.country?.toLowerCase()
    return countryLang === lang.split('-')[1].toLowerCase()
  })

  if (
    allowsCountry < 0 &&
    router.isReady &&
    lang === 'en-gb' &&
    vendor.locations[0]?.country?.toLowerCase() === 'ie'
  ) {
    router.push(`/en-ie/office-catering/vendors/${vendor.permalink}`)
    return null
  }
  if (allowsCountry < 0 && router.isReady) router.push('/office-catering/vendors')
}
