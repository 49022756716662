import { useSaveStandingOrderChangesMutation } from '@/generated/graphql'
import { useCallback } from 'react'
import { useTimeout } from 'react-use'

export const useSaveStandingOrderMutation = (
  basketId?: string | null,
  editSessionId?: string | null,
) => {
  const [hideUpdatedMessage, _, showUpdated] = useTimeout(5000)
  const [run, result] = useSaveStandingOrderChangesMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      showUpdated()
    },
  })

  const error =
    result.error ||
    (result.called && !result.loading && !result.data?.basket ? 'Unknown error' : null)

  const saveStandingOrder = useCallback(() => {
    if (!basketId || !editSessionId) return
    void run({
      variables: {
        basketId,
        editSessionId,
      },
    })
  }, [run, editSessionId, basketId])

  return {
    run: saveStandingOrder,
    loading: result.loading,
    updated: result.called && !hideUpdatedMessage(),
    error,
  }
}
