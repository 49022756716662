import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import { Allergen, Dietary } from '@/generated/graphql'
import { ExpandMore } from '@mui/icons-material'
import { MenuItemFilter, MenuItemFilterSection } from '../../../domain/menu-item-filter'
import Icon, { IconType } from '@caterdesk/ui--icon'
import React from 'react'
import useGmFiltersStore from '../../../helpers/gmFiltersStore'

type Props = {
  type: MenuItemFilterSection
  label: string
  filters: MenuItemFilter[]
  selectedFilters: MenuItemFilter[]
  visibility: boolean
}

const FilterSection: React.FC<Props> = ({ type, label, filters, selectedFilters, visibility }) => {
  const { setDietaryPreferences } = useGmFiltersStore()

  const emptyFilter = filters.every((filter) => filter.count === 0)
  if (!visibility || emptyFilter) return null

  const handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: Dietary | Allergen) => {
    const filter = filters.find((f) => f.value === value)
    const selected = selectedFilters.find((f) => f.value === value)
    const newFilters =
      !selected && filter
        ? [...selectedFilters, filter]
        : selectedFilters.filter((f) => f.value !== value)
    setDietaryPreferences(type, newFilters)
  }

  return (
    <Accordion sx={{ width: '100%' }} defaultExpanded>
      <AccordionSummary sx={{ width: '5' }} expandIcon={<ExpandMore />}>
        {label}
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup>
          <Grid container spacing={1}>
            {filters
              .filter((filter) => filter.count >= 1)
              .map((filter) => {
                const isChecked = selectedFilters.some((f) => f.value === filter.value)

                return (
                  <Grid item key={filter.label}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isChecked}
                          onChange={(event) => handleChange(event, filter.value)}
                        />
                      }
                      label={
                        <Box display="flex" alignItems="center" minWidth={180}>
                          <Icon
                            icon={filter?.icon || (filter.value as IconType)}
                            size={24}
                            fill={filter.color}
                          />
                          <Typography sx={{ marginLeft: 1 }}>
                            <Stack flexDirection={'row'}>
                              <Typography variant="body2" color="textSecondary">
                                {filter.label}
                              </Typography>
                              <Typography variant="body2" color="textSecondary" marginLeft={1}>
                                ({filter.count})
                              </Typography>
                            </Stack>
                          </Typography>
                        </Box>
                      }
                    />
                  </Grid>
                )
              })}
          </Grid>
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  )
}

export default FilterSection
