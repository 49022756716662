import React from 'react'
import { useMedia } from 'react-use'
import { amountToPriceString, Currency } from '@caterdesk/utils--money'
import { TextExtraSmall } from '@caterdesk/ui--typography'
import Stack from '@caterdesk/ui--stack'
import DietaryListMui from '@/components/DietaryListMui'
import Box from '@caterdesk/ui--box'
import { mobileMediaQuery } from '@caterdesk/ui--theme'
import { SelectedOptionItem } from './menu-item-modal'
import { MenuItemOption, MenuOptionItem } from '../../../domain/menu-item-option'
import { Checkbox, FormControlLabel } from '@mui/material'

type Props = {
  option: MenuItemOption
  currency: Currency
  selectedOptionItems?: Map<string, SelectedOptionItem>
  selectOptionItem: (option: MenuItemOption, item: MenuOptionItem) => void
}

export const TickOptions: React.FC<Props> = ({
  option,
  currency,
  selectedOptionItems,
  selectOptionItem,
}) => {
  const isMobileScreen = useMedia(mobileMediaQuery, false)

  return (
    <Box padding={{ y: isMobileScreen ? 8 : 16 }} borderBottom>
      <Stack direction="vertical" alignItems="stretch" spacing={8} divider={isMobileScreen}>
        {option.items.map((item) => {
          const priceToShow = item.priceCustomerFacingExTax
          return (
            <Box key={item._id} padding={{ x: isMobileScreen ? 16 : 24 }}>
              <Stack alignItems="start" justifyContent="space-between" key={item._id}>
                <FormControlLabel
                  key={item?._id}
                  label={item.item.name}
                  disabled={
                    !selectedOptionItems?.has(item._id) &&
                    selectedOptionItems?.size === option.maxQty
                  }
                  control={
                    <Checkbox
                      size="small"
                      checked={selectedOptionItems ? selectedOptionItems.has(item._id) : false}
                      onChange={() => selectOptionItem(option, item)}
                      name={option.name || 'option'}
                    />
                  }
                />
                <Stack spacing={8} justifyContent="space-between">
                  <Stack spacing={4} divider>
                    <DietaryListMui dietaries={item.item.dietaries} allergens={[]} maxToShow={3} />
                    <DietaryListMui
                      maxToShow={3}
                      dietaries={[]}
                      allergens={item.item.allergens}
                      subAllergens={item.item.subAllergens}
                    />
                  </Stack>
                  {typeof priceToShow === 'number' && (
                    <TextExtraSmall>
                      {amountToPriceString(currency, priceToShow, true)}
                    </TextExtraSmall>
                  )}
                </Stack>
              </Stack>
            </Box>
          )
        })}
      </Stack>
    </Box>
  )
}
