import { CircularProgress } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React from 'react'

type Props = {
  checkout: () => void
  loading?: boolean
  disableSubmission: boolean
}

const BasketFooter: React.FC<Props> = ({ disableSubmission, checkout, loading }) => {
  return (
    <LoadingButton
      color="primary"
      disabled={disableSubmission}
      loadingIndicator={<CircularProgress color="inherit" size={16} />}
      onClick={checkout}
      loading={loading}
      sx={{ width: '40%' }}
      variant="contained"
    >
      Checkout
    </LoadingButton>
  )
}

export default BasketFooter
