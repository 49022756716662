import styled from 'styled-components'
import { Text } from '@caterdesk/ui--typography'
import React, { useEffect, useMemo, useState } from 'react'
import MenuItemCard from './menu-item-card'
import { MenuCategory } from '../../domain/menu-category'
import { MenuVendor } from '../../domain/menu-vendor'
import { AddItemPayload } from '../states/menu-page/add-item-mutation'
import LoadingIndicator from '@/components/LoadingIndicator'
import { Box, Grid as MuiGrid } from '@mui/material'
import { List, WindowScroller } from 'react-virtualized'
import 'react-virtualized/styles.css'
import { useAppState } from '../states/app'
import { AuthState, useAuthState } from '../states/auth'
import { MenuItem } from '../../domain/menu-item'

const CategorySection = styled.div`
  margin-bottom: 45px;
`

const CategoryTitle = styled.h2`
  font-weight: ${(p) => p.theme.fontWeights.medium};
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 22px;
  color: ${(p) => p.theme.colors.primary};
  text-transform: uppercase;
  margin: 0 0 ${(p) => p.theme.spacings[3]}px;
`

const CategoryDivider = styled.div`
  background: linear-gradient(
    to right,
    ${(p) => p.theme.colors.primary} 0%,
    rgba(79, 191, 112, 0.0001) 98.64%
  );
  mix-blend-mode: normal;
  opacity: 0.4;
  height: 2px;
  width: 50%;
  margin: 16px 0;
`

const MassiveBox = styled.div`
  margin: 100px 0;
  text-align: center;
`

export type Props = {
  vendor: MenuVendor
  categories: MenuCategory[]
  taxAcronym: string
  loadingMore: boolean
  reloading: boolean
  useVirtualisedList?: boolean
  onAddItemToBasketClick: (item: AddItemPayload) => void
}

export const MenuItemsSection: React.FC<Props> = ({
  vendor,
  categories,
  taxAcronym,
  loadingMore,
  reloading,
  useVirtualisedList,
  onAddItemToBasketClick,
}) => {
  const cardSize = 12
  const numberOfCardsInColumn = 1

  const [isClient, setIsClient] = useState(false)
  const { userState } = useAppState()
  const authState = useAuthState()

  useEffect(() => {
    setIsClient(true)
  }, [])

  const accountId = authState.type === 'authenticated' ? authState.user.account?.id : null

  const hasAccessToItem = (
    item: MenuItem,
    authState: AuthState,
    accountId: string | null | undefined,
  ) => {
    if (item.isPrivate && authState.type === 'unauthenticated') return false

    if (
      item.isPrivate &&
      accountId &&
      item.privateAccessAccountIdArray &&
      item.privateAccessAccountIdArray.length > 0 &&
      !item.privateAccessAccountIdArray.includes(Number(accountId))
    ) {
      return false
    }

    return true
  }

  const elements = useMemo(() => {
    if (!useVirtualisedList) return null
    return categories.flatMap((category, c) => {
      const res: {
        type: 'category' | 'item'
        height: number
        component: React.ReactElement
      }[] = [
        {
          type: 'category' as const,
          height: 40,
          component: (
            <Box key={c}>
              <CategoryTitle>{category.name}</CategoryTitle>
              <CategoryDivider />
            </Box>
          ),
        },
      ]

      for (let i = 0; i < category.items.length; i += numberOfCardsInColumn) {
        const allItems = Array.from({ length: numberOfCardsInColumn })
          .filter((_, j) => {
            const item = category.items[i + j]
            if (!item) return false
            const activeFrom =
              item.activeFrom && userState.date
                ? new Date(userState.date) >= new Date(item.activeFrom)
                : true
            const activeTo =
              item.activeTo && userState.date
                ? new Date(userState.date) <= new Date(item.activeTo)
                : true
            if (!activeFrom || !activeTo) {
              return false
            }

            if (!hasAccessToItem(item, authState, accountId)) {
              return false
            }
            return true
          })
          .map((_, j) => (
            <MenuItemCard
              key={`${c}${i}${j}`}
              numberInCol={numberOfCardsInColumn}
              taxAcronym={taxAcronym}
              item={category.items[i + j]}
              vendor={vendor}
              onAddItemToBasketClick={onAddItemToBasketClick}
            />
          ))
        if (!allItems.length) continue

        const lastItemMarginBottom = category.items.length <= i + 1 ? 32 : 0
        const itemHeight = 280
        const itemPadding = 16

        res.push({
          type: 'item' as const,
          height: itemHeight + itemPadding + lastItemMarginBottom,
          component: (
            <Box
              key={`${c}${i}`}
              sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(${numberOfCardsInColumn}, 1fr)`,
                gap: 2,
                py: `${itemPadding}px`,
                mb: `${lastItemMarginBottom}px`,
              }}
            >
              {allItems}
            </Box>
          ),
        })
      }
      if (res.length <= 1) return []

      return res
    })
  }, [
    useVirtualisedList,
    categories,
    numberOfCardsInColumn,
    taxAcronym,
    vendor,
    onAddItemToBasketClick,
    userState,
    authState,
  ])

  const rowHeights = useMemo(() => elements?.map(({ height }) => height), [elements])

  if (!isClient) return null

  return categories.length ? (
    <>
      {!useVirtualisedList &&
        categories.map((category, i) => (
          <CategorySection key={i} id={`scrollTo-${category.name}`}>
            <CategoryTitle>{category.name}</CategoryTitle>
            <CategoryDivider />
            <MuiGrid width="100%" container spacing={1}>
              {reloading ? (
                <LoadingIndicator />
              ) : (
                category.items
                  .filter((item) => hasAccessToItem(item, authState, accountId))
                  .map((item) => (
                    <MuiGrid item md={cardSize} sm={cardSize} xs={12} key={`${i}-${item._id}`}>
                      <MenuItemCard
                        numberInCol={numberOfCardsInColumn}
                        taxAcronym={taxAcronym}
                        item={item}
                        vendor={vendor}
                        onAddItemToBasketClick={onAddItemToBasketClick}
                      />
                    </MuiGrid>
                  ))
              )}
            </MuiGrid>
          </CategorySection>
        ))}

      {useVirtualisedList && elements && (
        <Box
          sx={{
            flex: 1,
            flexGrow: 1,
            minHeight: 1000,
          }}
        >
          <WindowScroller>
            {({ height, isScrolling, registerChild, scrollTop }) => (
              // @ts-expect-error - react-window types are incorrect
              <div ref={registerChild}>
                <List
                  autoHeight
                  height={height}
                  isScrolling={isScrolling}
                  rowCount={elements.length}
                  rowHeight={({ index }) => rowHeights?.[index] || 0}
                  width={2000}
                  rowRenderer={({ index, style, isVisible }) => {
                    if (!isVisible) return null
                    const element = elements[index]
                    return <div style={style}>{element.component}</div>
                  }}
                  autoWidth
                  autoContainerWidth
                  scrollTop={scrollTop}
                />
              </div>
            )}
          </WindowScroller>
        </Box>
      )}
      {loadingMore ? <LoadingIndicator size="small" /> : null}
    </>
  ) : (
    <MassiveBox>
      <Text>Oops, looks like no menu items match this filter selection.</Text>
      <Text>Remove the filter/s to view all menu items.</Text>
    </MassiveBox>
  )
}
