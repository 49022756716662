import { create } from 'zustand'
import {
  EVERYTHING_FILTER_CATEGORY,
  MenuFilterCategory,
  SelectedMenuFilterCategory,
} from '../domain/menu-category-filter'
import { usePathname, useSearchParams } from 'next/navigation'
import React, { useEffect } from 'react'
import { MenuItemFilter } from '../domain/menu-item-filter'
import { useRouter } from 'next/router'

type GmFiltersStore = {
  itemName: string
  selectedCategory: SelectedMenuFilterCategory
  goals: MenuItemFilter[]
  allergens: MenuItemFilter[]
  dietaries: MenuItemFilter[]
  initialised: boolean
  setItemName: (itemName: string) => void
  setSelectedCategory: (category: MenuFilterCategory) => void
  unsetSelectedCategory: () => void
  setDietaryPreferences: (
    type: 'allergens' | 'dietaries' | 'goals',
    dietaryPreferences: MenuItemFilter[],
  ) => void
  clearFilters: () => void
  initialiseFilters: ({
    itemName,
    category,
    goals,
    dietaries,
    allergens,
  }: {
    itemName: string
    category: MenuFilterCategory | SelectedMenuFilterCategory
    goals: MenuItemFilter[]
    dietaries: MenuItemFilter[]
    allergens: MenuItemFilter[]
  }) => void
}

const useInternalGmFiltersStore = create<GmFiltersStore>((set) => {
  return {
    itemName: '',
    selectedCategory: EVERYTHING_FILTER_CATEGORY,
    selectedGoals: [],
    goals: [],
    allergens: [],
    dietaries: [],
    initialised: false,
    setItemName: (itemName) => set({ itemName }),
    setSelectedCategory: (selectedCategory) => set({ selectedCategory }),
    unsetSelectedCategory: () => set({ selectedCategory: EVERYTHING_FILTER_CATEGORY }),
    setDietaryPreferences: (type, selected) =>
      set({
        [type]: selected,
      }),
    clearFilters: () => {
      set({
        itemName: '',
        selectedCategory: EVERYTHING_FILTER_CATEGORY,
        goals: [],
        allergens: [],
        dietaries: [],
      })
    },
    initialiseFilters: ({ itemName, category, goals, dietaries, allergens }) =>
      set({
        itemName,
        selectedCategory: category,
        goals,
        dietaries,
        allergens,
        initialised: true,
      }),
  }
})

const GmFiltersStoreContext = React.createContext<GmFiltersStore | undefined>(undefined)

export const GmFiltersStoreProvider: React.FC<{
  children: React.ReactNode
  useServerSideItems?: boolean
}> = ({ children, useServerSideItems }) => {
  const store = useInternalGmFiltersStore()
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const updateQueryParams = () => {
    const params = new URLSearchParams(searchParams || undefined)

    if (store.itemName) {
      params.set('itemName', store.itemName)
    } else {
      params.delete('itemName')
    }

    if (store.selectedCategory) {
      params.set('category', store.selectedCategory.name)
    }

    if (store.goals.length) {
      params.set('goals', store.goals.map((f) => f.value).join(','))
    }

    if (store.dietaries.length) {
      params.set('dietaries', store.dietaries.map((f) => f.value).join(','))
    }

    if (store.allergens.length) {
      params.set('allergens', store.allergens.map((f) => f.value).join(','))
    }

    if (useServerSideItems) {
      history.pushState({}, '', `${pathname}?${params}`)
    } else {
      router.replace(`${pathname}?${params}`)
    }
  }

  useEffect(() => {
    if (!store.initialised) return
    updateQueryParams()
  }, [
    store.initialised,
    store.itemName,
    store.selectedCategory,
    store.goals,
    store.dietaries,
    store.allergens,
  ])

  return <GmFiltersStoreContext.Provider value={store}>{children}</GmFiltersStoreContext.Provider>
}

const useGmFiltersStore = () => {
  const context = React.useContext(GmFiltersStoreContext)

  if (!context) {
    throw new Error('useGmFiltersStore must be used within a GmFiltersStoreContext')
  }

  return context
}

export default useGmFiltersStore
