import { format } from 'date-fns'
import { MenuVendor } from '../../../domain/menu-vendor'
import { ValidUserBasketSettings } from '../../../domain/user-basket-settings'
import {
  AuthAccountFragment,
  BasketFragment,
  GmServiceFormatInput,
  MenuVendorLocationWithDeliveryRegionsFragment,
  UpdateBasketMutation,
  useUpdateBasketMutation as useGeneratedMutation,
} from '@/generated/graphql'
import { basketLocationToInputFormat } from '@/components/page-specific/gm/helpers/addresses'
import { useUtmData } from '@/hooks/useUtmData'

export const useUpdateBasketMutation = () => {
  const [run, result] = useGeneratedMutation()
  const utmData = useUtmData()

  const error =
    result.error ||
    (result.called && !result.loading && !result.data?.basket ? 'Unknown error' : null)

  const updateBasket = ({
    basket,
    vendor,
    selectedVendorLocation,
    account,
    userBasketSettings,
    onCompleted,
  }: {
    basket: BasketFragment
    vendor?: MenuVendor
    selectedVendorLocation?: MenuVendorLocationWithDeliveryRegionsFragment
    account?: AuthAccountFragment | null
    userBasketSettings?: ValidUserBasketSettings
    onCompleted?: (data: UpdateBasketMutation) => void
  }) => {
    void run({
      variables: {
        basketId: basket.id,
        basketPayload: {
          customName: basket.customName,
          ...(userBasketSettings && {
            settings: {
              ...(account?.id &&
                userBasketSettings && {
                  account: {
                    id: Number(account.id),
                    name: account.name,
                    locationId:
                      userBasketSettings?.location.__typename === 'AccountLocation'
                        ? Number(userBasketSettings.location.id)
                        : null,
                  },
                }),
              ...(basket.settings.account?.id &&
                userBasketSettings && {
                  account: {
                    id: basket.settings.account.id,
                    name: basket.settings.account.name,
                    locationId:
                      userBasketSettings?.location.__typename === 'AccountLocation'
                        ? Number(userBasketSettings.location.id)
                        : null,
                  },
                }),
              billingAddressSameAsDelivery: basket.settings.billingAddressSameAsDelivery,
              paymentMethod: basket.settings.paymentMethod,
              ...(userBasketSettings && {
                location: basketLocationToInputFormat(userBasketSettings.location),
                deliveryDate: format(new Date(userBasketSettings.date), 'yyyy-MM-dd'),
                deliveryTimeSlot: userBasketSettings.time,
                headCount: userBasketSettings.headCount,
              }),
              ...(vendor &&
                selectedVendorLocation && {
                  vendor: {
                    id: Number(vendor.id),
                    name: vendor.companyName,
                    locationId: Number(selectedVendorLocation.id),
                  },
                }),
            },
          }),
          options: {
            format: GmServiceFormatInput.Individual,
            rep: basket.options?.rep || false,
            isHighRisk: basket.options?.isHighRisk || false,
            thermalBox: basket.options?.thermalBox || false,
            addons: {
              napkins: basket.options?.addons?.napkins || false,
              plates: basket.options?.addons?.plates || false,
              service: basket.options?.addons?.service || false,
            },
            notes: {
              cart: basket.options?.notes?.cart || '',
              account: basket.options?.notes?.account || '',
              vendor: basket.options?.notes?.vendor || '',
            },
            budgetCode: basket.options?.budgetCode || '',
          },
          utmData,
        },
      },
      onCompleted,
    })
  }

  return {
    run: updateBasket,
    loading: result.loading,
    error,
  }
}
