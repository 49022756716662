import { graphql } from '@/generated/gql'
import { useLazyQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { SelectorBasket } from '.'

const query = graphql(`
  query BasketSelection_GetBaskets($filters: GmBasketFiltersInput, $limit: Int, $offset: Int) {
    me {
      gmBaskets(filters: $filters, limit: $limit, offset: $offset) {
        rows {
          id
          friendlyId
          customName
        }
      }
    }
  }
`)

type Props = {
  vendorId?: string
}

export const useAuthenticatedUserBasketSelection = ({ vendorId }: Props) => {
  const [run, { data, refetch, called, loading: queryLoading }] = useLazyQuery(query)
  const [baskets, setBaskets] = useState<Array<SelectorBasket>>([])
  const [loading, setLoading] = useState(false)

  const onCreate = useCallback(
    (newBasket: SelectorBasket) => {
      setBaskets((prev) => [newBasket, ...prev])
      refetch()
    },
    [refetch],
  )

  const onDelete = useCallback(
    (basketId: string) => {
      setBaskets((prev) => {
        const filteredBaskets = prev.filter((basket) => basketId !== basket.id)
        return filteredBaskets
      })
      refetch()
    },
    [refetch],
  )

  useEffect(() => {
    setBaskets(data?.me.gmBaskets.rows ?? [])
    setLoading(false)
  }, [data])

  useEffect(() => {
    if (!vendorId) return
    setLoading(true)
    run({ variables: { offset: 0, limit: 10, filters: { vendorId } } })
  }, [vendorId, run])

  return {
    baskets,
    loading: loading || queryLoading,
    onCreate,
    onDelete,
    called,
  }
}
