import { Button, Menu, MenuItem, Divider } from '@mui/material'
import { MenuItemFilterSections } from '../../../domain/menu-item-filter'
import FilterSection from './FilterSection'
import React from 'react'
import useGmFiltersStore from '../../../helpers/gmFiltersStore'
import useTenant from '@/hooks/useTenant'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
interface ModalFiltersProps {
  filterSections: MenuItemFilterSections
  anchorEl: null | HTMLElement
  open: boolean
  handleClose: () => void
  clearFilters: () => void
}

const ModalFilters: React.FC<ModalFiltersProps> = ({
  filterSections,
  anchorEl,
  open,
  handleClose,
  clearFilters,
}) => {
  const tenant = useTenant()
  const { goals, allergens, dietaries } = useGmFiltersStore()

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      slotProps={{
        paper: {
          style: {
            maxHeight: 940,
            width: '60ch',
          },
        },
      }}
    >
      <MenuItem>
        <Button
          onClick={clearFilters}
          variant="contained"
          color="inverted"
          fullWidth
          endIcon={<ClearIcon />}
        >
          Clear filters
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          color="inverted"
          fullWidth
          endIcon={<CheckIcon />}
        >
          Apply filters
        </Button>
      </MenuItem>
      <Divider />
      <MenuItem>
        <FilterSection
          label="Goals"
          filters={filterSections.goals}
          type="goals"
          selectedFilters={goals}
          visibility={true}
        />
      </MenuItem>
      <MenuItem>
        <FilterSection
          label="Dietaries"
          filters={filterSections.dietaries}
          type="dietaries"
          selectedFilters={dietaries}
          visibility={tenant.features?.cateringFilters?.dietary ?? true}
        />
      </MenuItem>
      <MenuItem>
        <FilterSection
          label="Allergens"
          filters={filterSections.allergens}
          type="allergens"
          selectedFilters={allergens}
          visibility={tenant.features?.cateringFilters?.allergens ?? true}
        />
      </MenuItem>
    </Menu>
  )
}

export default ModalFilters
