import { useCallback, useEffect, useState } from 'react'
import { useAppState } from './app'
import {
  MenuVendorLocationWithDeliveryRegionsFragment,
  ServiceType,
  useGetVendorsInRangeLocationsLazyQuery,
  UserBasketSettingsLocationFragment,
} from '@/generated/graphql'
import { basketLocationToAddress } from '@caterdesk/utils--gm-validation'
import { getCountryCodeFromName } from '@/helpers/multiRegion/countries'
import { getDateTimeObject } from '../../domain/user-basket-settings'
import { getCutOffLocations, validateAll } from '../../helpers/validation'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import sortBy from 'lodash/sortBy'
import { isEqual } from 'lodash'
import { taxAcronym as getTaxAcronym } from '@/helpers/vatRegions'

type Props = {
  vendorId: string
}

export const useSelectedVendorLocation = ({ vendorId }: Props) => {
  const { userState, setErrors, errors } = useAppState()
  const cutOff5pm = useFeatureFlag(SPLITS.GM_CUTOFF_5PM)
  const [selectedVendorLocation, setSelectedVendorLocation] =
    useState<MenuVendorLocationWithDeliveryRegionsFragment>()
  const [deliveryFee, setDeliveryFee] = useState<number>(15)
  const [minOrderValueExTax, setMinOrderValueExTax] = useState<number | null>(null)
  const [basketIsFeedrAdminApproved, setBasketIsFeedrAdminApproved] = useState(false)
  const [inRangeVendorLocations, setInRangeVendorLocations] = useState<
    MenuVendorLocationWithDeliveryRegionsFragment[] | null
  >(null)
  const [availableVendorLocations, setAvailableVendorLocations] = useState<
    MenuVendorLocationWithDeliveryRegionsFragment[]
  >([])
  const [getVendorsInRangeLocations] = useGetVendorsInRangeLocationsLazyQuery()

  const taxAcronym = getTaxAcronym(selectedVendorLocation?.country || '')

  const updateDeliveryFee = useCallback(
    (vendorLocation: MenuVendorLocationWithDeliveryRegionsFragment) => {
      const newDeliveryFee = sortBy(
        vendorLocation.deliveryRegions,
        (region) => region.orderPosition,
      ).find((d) => d.serviceType === ServiceType.Gm)?.deliveryFee?.total
      if (!newDeliveryFee && newDeliveryFee !== 0) throw new Error('Delivery fee not found')
      setDeliveryFee(newDeliveryFee)
    },
    [],
  )

  const updateMinOrderValue = useCallback(
    (vendorLocation: MenuVendorLocationWithDeliveryRegionsFragment) => {
      const region = sortBy(vendorLocation.deliveryRegions, (region) => region.orderPosition).find(
        (d) => d.serviceType === ServiceType.Gm,
      )
      const minOrderVal = region?.minOrderValueExTax
      const newMinOrderValue =
        minOrderVal ??
        // safeguard against delivery regions without minOrderValue
        (minOrderVal ? minOrderVal * 100 : null)
      if (!newMinOrderValue && newMinOrderValue !== 0)
        console.error(`Minimum order value for region ${region?.id} not found`)
      setMinOrderValueExTax(newMinOrderValue)
    },
    [],
  )

  const validate = useCallback(() => {
    if (!inRangeVendorLocations || !userState) return false
    const activeLocations = getCutOffLocations(inRangeVendorLocations, cutOff5pm)
    const { newErrors, chosenVendorLocations } = validateAll({
      inRangeVendorLocations: activeLocations,
      userState,
      basketIsFeedrAdminApproved,
      useDeliveryTimes: cutOff5pm,
    })
    setAvailableVendorLocations(chosenVendorLocations)
    if (chosenVendorLocations.length) {
      setSelectedVendorLocation(chosenVendorLocations[0])
      updateDeliveryFee(chosenVendorLocations[0])
      updateMinOrderValue(chosenVendorLocations[0])
    }
    if (!isEqual(newErrors, errors)) setErrors(newErrors)
    return !Object.values(newErrors).length
  }, [
    basketIsFeedrAdminApproved,
    cutOff5pm,
    inRangeVendorLocations,
    errors,
    setErrors,
    updateDeliveryFee,
    updateMinOrderValue,
    userState,
  ])

  const getInRangeLocations = useCallback(
    ({
      vendorId,
      location,
      date,
      time,
    }: {
      vendorId: string
      location: UserBasketSettingsLocationFragment
      date?: number
      time?: string
    }) => {
      const { latitude, longitude, country } = basketLocationToAddress(location)
      if (!latitude || !longitude) return
      const countryCode = getCountryCodeFromName(country) || country || null
      const dateTimeObject = getDateTimeObject({ date, time })
      const dateTime = dateTimeObject
        ? {
            ...dateTimeObject,
            timezone: 'UTC',
          }
        : null
      getVendorsInRangeLocations({
        variables: {
          vendorId,
          lat: latitude,
          lng: longitude,
          serviceType: ServiceType.Gm,
          countryCode,
          dateTime,
        },
        onCompleted: (newData) => {
          setInRangeVendorLocations(newData.vendor.inRangeLocations)
          validate()
          if (newData.vendor.inRangeLocations.length) {
            const closestLocation = newData.vendor.inRangeLocations[0]
            setSelectedVendorLocation(closestLocation)
            updateDeliveryFee(closestLocation)
            updateMinOrderValue(closestLocation)
          }
        },
      })
    },
    [
      getVendorsInRangeLocations,
      updateDeliveryFee,
      updateMinOrderValue,
      setSelectedVendorLocation,
      validate,
    ],
  )

  useEffect(() => {
    validate()
  }, [userState, inRangeVendorLocations, validate])

  useEffect(() => {
    if (!userState.location) return
    getInRangeLocations({ vendorId, location: userState.location })
  }, [userState.location, userState.date, userState.time, vendorId, getInRangeLocations])

  return {
    taxAcronym,
    selectedVendorLocation,
    availableVendorLocations,
    deliveryFee,
    minOrderValueExTax,
    setBasketIsFeedrAdminApproved,
    validate,
  }
}
