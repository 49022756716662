import { useCallback } from 'react'
import { MenuItemOption, MenuOptionItem } from '../../../domain/menu-item-option'
import {
  BasketFragment,
  BasketItemFragment,
  UpdateBasketItemInput,
  useUpdateItemInBasketMutation,
} from '@/generated/graphql'

export type UpdateBasketItemPayload = Pick<UpdateBasketItemInput, 'qty' | 'acknowledgedAt'> & {
  options?: {
    option: MenuItemOption
    optionItem: MenuOptionItem
    qty: number
  }[]
}

export type UpdateItemInBasket = (
  basket: BasketFragment,
  item: Pick<BasketItemFragment, 'itemReference'>,
  payload: UpdateBasketItemPayload,
) => void

export const useUpdateItemMutation = (
  setDeliveryFee?: number,
  isStandingOrderTemplate?: boolean,
  editSessionId?: string | null,
) => {
  const [run, result] = useUpdateItemInBasketMutation()

  if (!setDeliveryFee && setDeliveryFee !== 0) throw new Error('No delivery fee set')

  const error =
    result.error ||
    (result.called && !result.loading && !result.data?.basket ? 'Unknown error' : null)

  const updateItemInBasket: UpdateItemInBasket = useCallback(
    (basket, item, itemPayload) => {
      const optimisticItems: BasketItemFragment[] = basket.items.map((basketItem) =>
        basketItem.itemReference === item.itemReference
          ? {
              ...basketItem,
              ...(typeof itemPayload.qty === 'number' && { qty: itemPayload.qty }),
              ...(itemPayload.options && {
                options: itemPayload.options.map(({ qty, option, optionItem }) => ({
                  qty,
                  item: optionItem.item,
                  category: option,
                  optionItem,
                })),
              }),
            }
          : basketItem,
      )

      void run({
        variables: {
          basketId: basket.id,
          editSessionId: editSessionId ?? null,
          itemReference: item.itemReference,
          isStandingOrderTemplate: Boolean(isStandingOrderTemplate),
          itemPayload: {
            ...itemPayload,
            options: itemPayload.options?.map(({ option, optionItem, qty }) => ({
              categoryId: option._id,
              item: optionItem.item._id,
              optionItemId: optionItem._id,
              qty,
            })),
          },
        },
        optimisticResponse: {
          basket: {
            ...basket,
            items: optimisticItems,
          },
        },
      })
    },
    [run, editSessionId, isStandingOrderTemplate],
  )

  return {
    run: updateItemInBasket,
    loading: result.loading,
    error,
  }
}
