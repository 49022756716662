import { useCallback, useEffect, useState } from 'react'
import { SelectorBasket } from '.'
import { getLocalBasketDataForVendor, setLocalBasketDataForVendor } from '../local-basket'

type Props = {
  vendorId?: string
}

export const useUnauthenticatedUserBasketSelection = ({ vendorId }: Props) => {
  const [called, setCalled] = useState(false)
  const [baskets, setBaskets] = useState<Array<SelectorBasket>>([])

  const onCreate = useCallback(
    (newBasket: SelectorBasket) => {
      if (!vendorId) return
      setBaskets((prev) => {
        const newBaskets = [...prev, newBasket]
        return newBaskets
      })
    },
    [vendorId],
  )

  const onDelete = useCallback(
    (basketId: string) => {
      if (!vendorId) return
      setBaskets((prev) => {
        const filteredBaskets = prev.filter((basket) => basketId !== basket.id)
        return filteredBaskets
      })
    },
    [vendorId],
  )

  const update = useCallback(
    (updatedBasketData: SelectorBasket) => {
      if (!vendorId) return
      setBaskets((prev) => {
        const updatedBaskets = prev.map((existingBasket) =>
          existingBasket.id === updatedBasketData.id ? updatedBasketData : existingBasket,
        )
        setLocalBasketDataForVendor(vendorId, updatedBaskets)
        return updatedBaskets
      })
    },
    [vendorId],
  )

  useEffect(() => {
    if (!vendorId) return
    const localBaskets = getLocalBasketDataForVendor(vendorId)
    if (Array.isArray(localBaskets)) setBaskets(localBaskets)
    else if (localBaskets) setBaskets([{ id: localBaskets }])
    setCalled(true)
  }, [vendorId])

  useEffect(() => {
    if (!vendorId || !called) return
    setLocalBasketDataForVendor(vendorId, baskets)
  }, [vendorId, baskets, called])

  return {
    baskets,
    called,
    onCreate,
    onDelete,
    update,
  }
}
