import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import { apolloInit } from '@/helpers/apollo/ApolloClient'
import { GetStaticPaths, GetStaticProps } from 'next'
import { match, P } from 'ts-pattern'
import { compact, sortBy } from 'lodash'
import tenants from '@/generated/tenants.json'
import { get } from '@vercel/edge-config'

import Navigation from '@/components/page-specific/gm/components/navigation'
import VendorHeader from '@/components/page-specific/gm/components/vendor-header'
import InputBar from '@/components/page-specific/gm/components/input-bar'
import { MenuItemModal } from '@/components/page-specific/gm/components/menu-display/menu-item-card/menu-item-modal'
import { withRouter } from '@/components/page-specific/gm/utils/withRouter'
import {
  BasketItemFragment,
  GetVendorQuery,
  GetVendorQueryVariables,
  GetVendorDocument,
  GetVendorAvailabilityQuery,
  GetVendorAvailabilityQueryVariables,
  GetVendorAvailabilityDocument,
  TenantFromPrebuildFragment,
  BasketFragment,
} from '@/generated/graphql'
import { MenuItem } from '@/components/page-specific/gm/domain/menu-item'
import Toast from '@/components/page-specific/gm/components/toast'
import {
  AddressErrors,
  BasketErrors,
  DateErrors,
  TimeErrors,
} from '@/components/page-specific/gm/types/enums/Errors.enum'
import { useAppState } from '@/components/page-specific/gm/components/states/app'
import {
  UserBasketSettings,
  validateUserBasketSettings,
  ValidUserBasketSettings,
} from '@/components/page-specific/gm/domain/user-basket-settings'
import {
  Button,
  Stack,
  Box as MuiBox,
  Snackbar,
  AlertTitle,
  Alert,
  useTheme,
  useMediaQuery,
  Container,
  Dialog,
  Skeleton,
  AlertProps,
} from '@mui/material'
import withGm from '@/components/page-specific/gm/components/withGm'
import { useLanguage, useRouter } from '@/components/Link'
import Head from 'next/head'
import { getCurrencySymbol } from '@caterdesk/utils--money'
import { useSearchParams } from 'next/navigation'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import { useExistingOrder } from '@/components/page-specific/gm/components/states/useExistingOrder'
import { getTenantFromDomain } from '@/helpers/tenants'
import { GmFiltersStoreProvider } from '@/components/page-specific/gm/helpers/gmFiltersStore'
import { SUPPORTED_LOCALES } from '@/constants/languages'
import ConsolidatedBasket from '@/components/page-specific/gm/components/ConsolidatedBasket'
import ConsolidatedBasketHeader from '@/components/page-specific/gm/components/ConsolidatedBasket/consolidated-header'
import { useMappedBasketOrOrderDataToCartData } from '@/components/page-specific/gm/components/ConsolidatedBasket/useMappedBasketOrOrderDataToCartData'
import BasketFooter from '@/components/page-specific/gm/components/ConsolidatedBasket/basket-footer'
import OrderFooter from '@/components/page-specific/gm/components/ConsolidatedBasket/order-footer'
import StandingOrderFooter from '@/components/page-specific/gm/components/ConsolidatedBasket/standing-order-footer'
import ErrorSection from '@/components/page-specific/gm/components/ConsolidatedBasket/error-section'
import {
  generateBasketLink,
  generateOrderLink,
  generateStandingOrderLink,
} from '@/components/page-specific/gm/helpers/basket-link'
import { useBasketSelection } from '@/components/page-specific/gm/components/states/basket-selection'
import { useSelectedBasketState } from '@/components/page-specific/gm/components/states/selected-basket'
import { useAuthState } from '@/components/page-specific/gm/components/states/auth'
import { useUserBasketSettingsState } from '@/components/page-specific/gm/components/states/useUserBasketSettingsState'
import { AddItemPayload } from '@/components/page-specific/gm/components/states/menu-page/add-item-mutation'
import { numberFormat } from '@/helpers/numbers'
import NewDraftButton from '@/components/page-specific/gm/components/new-draft-button'
import { useMenuPageVendor } from '@/components/page-specific/gm/components/states/useMenuPageVendor'
import { useSelectedVendorLocation } from '@/components/page-specific/gm/components/states/useSelectedVendorLocation'
import MenuDisplay from '@/components/page-specific/gm/components/menu-display'
import { useMenuCountryRedirect } from '@/components/page-specific/gm/components/states/useMenuCountry'
import { useWindowSize } from '@/hooks/useWindowSize'

type Props = {
  vendorDetails: GetVendorQuery['vendor']
  itemData?: GetVendorAvailabilityQuery
  domain: string
  lang: string
  useServerSideItems?: boolean
  useVirtualisedList?: boolean
}

const ConsolidatedBasketWrapper: React.FC<
  PropsWithChildren<{ isMobile: boolean; open: boolean }>
> = ({ children, isMobile, open }) => {
  return isMobile ? (
    <Dialog fullScreen open={open}>
      {children}
    </Dialog>
  ) : (
    children
  )
}

const MenuPage: React.FC<Props> = ({
  vendorDetails,
  domain,
  lang,
  itemData,
  useServerSideItems,
  useVirtualisedList,
}) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const theme = useTheme()
  const language = useLanguage()
  const { height: verticalHeight } = useWindowSize()

  const orderIdParam = searchParams?.get('orderId') || ''
  const standingOrderIdParam = searchParams?.get('standingOrderId') || ''
  const basketIdParam = searchParams?.get('id') || searchParams?.get('basketId') || ''
  const preventAutomaticBasketSelection = Boolean(searchParams?.get('preventAutoBasketSelection'))

  const isEditingExistingOrder = Boolean(orderIdParam)
  const isEditingStandingOrder = Boolean(standingOrderIdParam)

  const showMultipleBaskets = useFeatureFlag(SPLITS.MULTIPLE_BASKETS_GM_MARKETPLACE)
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [orderDetailsModalState, setOrderDetailsModalState] = useState<{
    open: boolean
    createOnConfirm?: boolean
    itemToAddOnCreate?: AddItemPayload
  }>({ open: false })
  const [mobileBasketOpen, setMobileBasketOpen] = useState(false)

  const [editItemModal, setEditItemModal] = useState<
    | {
        type: 'displayed'
        basketItem: Pick<
          BasketItemFragment,
          'itemReference' | 'item' | 'qty' | 'acknowledgedAt' | 'note' | 'options'
        >
        menuItem: MenuItem
      }
    | { type: 'hidden' }
  >({ type: 'hidden' })
  const [snackbarType, setSnackbarType] = useState<'standingOrderSaved' | 'linkCopied' | null>(null)
  const [navHeight, setNavHeight] = useState(0)
  const { errors: appErrors, updateUserState, userState } = useAppState()
  const authState = useAuthState()

  const { vendor } = useMenuPageVendor({ vendorDetails, language })
  useMenuCountryRedirect({ vendor, lang })
  const {
    selectedVendorLocation,
    deliveryFee,
    minOrderValueExTax,
    taxAcronym,
    availableVendorLocations,
    setBasketIsFeedrAdminApproved,
    validate,
  } = useSelectedVendorLocation({
    vendorId: vendor.id,
  })

  const {
    selectedBasket,
    unselectedBaskets,
    setSelectedBasket,
    deleteBasket,
    createBasket,
    onBasketFetchError,
    onBasketFetchSuccess,
    ...basketSelectionState
  } = useBasketSelection({
    isAuthenticatedUser: authState.type === 'authenticated',
    vendorId:
      authState.type !== 'loading' &&
      router.isReady &&
      !isEditingExistingOrder &&
      !isEditingStandingOrder
        ? vendor.id
        : undefined,
    initialSelectedId: basketIdParam ?? null,
    preventAutomaticSelection: preventAutomaticBasketSelection,
  })

  const { set: setUserBasketSettings, value: userBasketSettingsValue } =
    useUserBasketSettingsState()

  const { basketDesignedUiCompatibleOrderData, ...existingOrderState } = useExistingOrder({
    orderId: orderIdParam,
    vendorId: vendor.id,
    onSuccessfulSave: ({ orderId }) => {
      router.push(`/confirmation/order/gm?orderId=${orderId}`)
    },
  })

  const { recalculatingFees, recalculatingTotals, orderDataCalled } = existingOrderState

  const {
    basketError,
    basket: basketData,
    minimumOrderReached: basketMinimumOrderReached,
    minSpendRemaining: basketMinSpendRemaining,
    ...basketState
  } = useSelectedBasketState({
    basketId: selectedBasket?.id ?? null,
    language,
    standingOrderId: standingOrderIdParam,
    deliveryFee,
    minOrderValueExTax,
    onBasketDataChange: useCallback(
      (newBasketData: BasketFragment) => {
        if (isEditingExistingOrder || isEditingStandingOrder || !router.isReady) return
        setUserBasketSettings({
          location: newBasketData.settings.location || undefined,
          date: new Date(newBasketData.settings.deliveryDate).getTime(),
          time: newBasketData.settings.deliveryTimeSlot,
          headCount: newBasketData.settings.headCount,
        })
      },
      [setUserBasketSettings, isEditingExistingOrder, isEditingStandingOrder, router.isReady],
    ),
  })

  const orderSelectedSettings = match<
    { isEditingStandingOrder: boolean; isEditingExistingOrder: boolean },
    UserBasketSettings
  >({ isEditingExistingOrder, isEditingStandingOrder })
    .with({ isEditingExistingOrder: true }, () => ({ ...basketDesignedUiCompatibleOrderData }))
    .with({ isEditingStandingOrder: true }, () => ({
      ...basketData,
      location: basketData?.settings.location || undefined,
      date: basketData ? new Date(basketData?.settings.deliveryDate).getTime() : undefined,
      time: basketData?.settings.deliveryTimeSlot,
      headCount: basketData?.settings.headCount,
      weekdays: basketData?.standingOrderSettings?.daysOfOccurrence ?? [],
    }))
    .otherwise(() => userBasketSettingsValue)

  const editingStandingOrderTemplate = Boolean(basketData?.standingOrderSettings)

  const mappedBasketorOrderDataToCartData = useMappedBasketOrOrderDataToCartData({
    orderData: {
      rawData: existingOrderState.orderData,
      grandTotals: existingOrderState.grandTotals,
      itemsState: existingOrderState.orderItems,
    },
    basketData,
    standingOrderData: basketData?.standingOrderSettings ? basketData.standingOrderSettings : null,
  })

  const isInitialLoad =
    !router.isReady ||
    authState.type === 'loading' ||
    match({ isEditingExistingOrder, isEditingStandingOrder })
      .with({ isEditingExistingOrder: true }, () => !orderDataCalled)
      .with({ isEditingStandingOrder: true }, () => !basketState.called)
      .otherwise(() => {
        return (
          !basketSelectionState.called ||
          Boolean(!basketSelectionState.justCreated && selectedBasket?.id && !basketState.called)
        )
      })

  const loadingOrderData =
    isInitialLoad ||
    match(isEditingExistingOrder)
      .with(true, () => Boolean(existingOrderState.loadingOrderData))
      .otherwise(() => false)

  const loadingStandingOrderData =
    isInitialLoad ||
    match(isEditingStandingOrder)
      .with(true, () => {
        return Boolean(basketState.loading)
      })
      .otherwise(() => false)

  const loadingBasketData =
    isInitialLoad ||
    match(isEditingExistingOrder)
      .with(true, () => false)
      .otherwise(
        () => basketState.loading || (!basketData && Boolean(basketSelectionState.loading)),
      )

  const loadingBasketDrawerItems = loadingOrderData || loadingBasketData || loadingStandingOrderData

  const isDataUpdating = recalculatingFees || recalculatingTotals || basketState.updating

  const vendorUpdatedItems = mappedBasketorOrderDataToCartData.items.filter(
    (item) => item.updatedByVendor,
  )

  const sortedImages = sortBy(vendor.images, (i) => i?.position)
  const vendorImages = compact(sortedImages.map((image) => image?.secureUrl))
  const minimumNoticePeriod = selectedVendorLocation?.details?.gmMinNoticePeriod

  const unselectedBasketsHeight = showMultipleBaskets ? 45 * unselectedBaskets.length : 0
  const newDraftButtonHeight = 20
  const extraPaddingHeight = 45
  const basketMaxHeight =
    verticalHeight -
    (!isMobileScreen ? navHeight : 0) -
    (!isMobileScreen ? unselectedBasketsHeight : 0) -
    extraPaddingHeight -
    newDraftButtonHeight

  const handleCreateBasket = (itemPayload?: AddItemPayload) => {
    const account = authState.type === 'authenticated' ? authState.user.account : null
    const validatedBasketSettings = validateUserBasketSettings(userBasketSettingsValue)
    const validated = validate()
    const availabilityId = vendor.gmAvailability?.id
    if (!validated || validatedBasketSettings.type !== 'success' || !availabilityId) {
      setOrderDetailsModalState({
        open: true,
        createOnConfirm: true,
        itemToAddOnCreate: itemPayload,
      })
      return
    }
    createBasket({
      vendor,
      vendorLocationId: selectedVendorLocation?.id || null,
      account,
      availabilityId,
      itemPayload,
      userBasketSettings: validatedBasketSettings.value,
    })
  }

  const handleSaveBasketName = (newName: string) => {
    if (!basketData) return
    basketState.updateBasket({
      basket: Object.assign(basketData, { customName: newName }),
    })
  }

  const handleUserBasketSettingsConfirm = (userBasketSettings: ValidUserBasketSettings) => {
    if (orderDetailsModalState.open && orderDetailsModalState.createOnConfirm) {
      handleCreateBasket(orderDetailsModalState.itemToAddOnCreate)
    } else if (basketData && selectedVendorLocation) {
      const account = authState.type === 'authenticated' ? authState.user.account : null
      basketState.updateBasket({
        basket: basketData,
        vendor,
        selectedVendorLocation,
        account,
        userBasketSettings,
        onCompleted: () => setOrderDetailsModalState({ open: false }),
      })
    } else setOrderDetailsModalState({ open: false })
  }

  const checkoutCallback = (basketId: string) => {
    const validated = validate()
    if (validated) {
      const usersValidatedState = validateUserBasketSettings(userBasketSettingsValue)
      if (usersValidatedState.type === 'success')
        handleUserBasketSettingsConfirm(usersValidatedState.value)
      router.push(`/office-catering/checkout/?id=${basketId}`)
    } else {
      setOrderDetailsModalState({ open: true })
    }
  }

  const onNavHeightChange = useCallback((height: number) => {
    setNavHeight(height)
  }, [])

  useEffect(() => {
    if (!router.isReady) return
    if (orderIdParam || standingOrderIdParam || editingStandingOrderTemplate) return
    if (
      appErrors.time === TimeErrors.TIME_PAST ||
      appErrors.date === DateErrors.DATE_PAST ||
      appErrors.date === DateErrors.INVALID_DATE ||
      appErrors.time === TimeErrors.INVALID_TIME ||
      appErrors.address === AddressErrors.VENDER_COVERAGE
    ) {
      setOrderDetailsModalState({ open: true })
    }
  }, [appErrors, orderIdParam, standingOrderIdParam, router.isReady, editingStandingOrderTemplate])

  useEffect(() => {
    match({ snackbarType, standingOrderUpdated: basketState.standingOrderUpdated })
      .with({ snackbarType: 'standingOrderSaved', standingOrderUpdated: false }, () =>
        setSnackbarType(null),
      )
      .with({ snackbarType: P.nullish, standingOrderUpdated: true }, () =>
        setSnackbarType('standingOrderSaved'),
      )
      .otherwise(() => {})
  }, [basketState.standingOrderUpdated, snackbarType])

  useEffect(() => {
    const vendorUrl = `https://${domain}/${lang}/office-catering/vendors/${vendor.permalink}`
    const imageUrl = vendorImages[0]
    if (userState.vendor?.url === vendorUrl && userState.vendor.imageUrl === imageUrl) return
    updateUserState({
      vendor: {
        imageUrl,
        url: vendorUrl,
      },
    })
  }, [domain, lang, vendor.permalink, vendorImages, updateUserState, userState])

  useEffect(() => {
    if (!basketData?.settings.feedrAdminApproved) return
    setBasketIsFeedrAdminApproved(true)
  }, [setBasketIsFeedrAdminApproved, basketData?.settings.feedrAdminApproved])

  useEffect(() => {
    if (!basketData) return
    onBasketFetchSuccess(basketData)
  }, [basketData, onBasketFetchSuccess])

  useEffect(() => {
    if (
      !selectedBasket?.id ||
      basketState.loading ||
      basketError?.basketId !== selectedBasket.id ||
      basketError?.error !== 'COULD_NOT_FETCH_BASKET'
    )
      return
    onBasketFetchError(selectedBasket?.id)
  }, [
    basketError?.basketId,
    basketError?.error,
    selectedBasket?.id,
    onBasketFetchError,
    basketState.loading,
  ])

  return (
    <div>
      <Navigation
        onClickBasketPreview={() => {
          setMobileBasketOpen(true)
        }}
        previewData={{
          itemQuantity: mappedBasketorOrderDataToCartData.summary.itemCount,
          grandTotal: mappedBasketorOrderDataToCartData.summary.grandTotal,
        }}
        newItemWasJustAdded={basketState.newItemJustAdded || existingOrderState.newItemWasJustAdded}
        onHeightChange={onNavHeightChange}
      />

      <Container>
        <MuiBox
          sx={{
            transition: 'margin-right 0.5s ease-in-out',
          }}
        >
          <Head>
            <title>{`${vendor.companyName} Menu | Office Catering`}</title>
            <meta name="title" content={`${vendor.companyName} Menu | Office Catering`} />
            <meta
              name="keywords"
              content={
                vendor.keywords ||
                `${vendor.companyName} menu, ${vendor.companyName} office catering`
              }
            />
            <meta
              name="description"
              content={
                vendor.descriptionShort ||
                'Corporate catering for all occasions from breakfast buffets and sandwich platters to salad boxes and sushi. Served straight to the office, just how you like it.'
              }
            />
            <link
              rel="canonical"
              href={`https://${domain}/${lang}/office-catering/vendors/${vendor.permalink}`}
            />
            {vendor.rating && vendor.rating >= 3 && (
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: `
                  {
                    "@context": "https://schema.org/",
                    "@type": "AggregateRating",
                    "itemReviewed": {
                      "@type": "Restaurant",
                      "image": "${vendor.logo}",
                      "name": "${vendor.companyName}",
                      "servesCuisine": ["${vendor.keywords?.replace(/,( +)?/g, '", "')}"],
                      "priceRange": "${Array.from({ length: vendor.priceLevel || 1 })
                        .map(() => getCurrencySymbol(vendor.currency))
                        .join('')}"
                    },
                    "ratingValue": "${vendor.rating}",
                    "bestRating": "4",
                    "ratingCount": "${vendor.totalRatings}"
                  }
                }`,
                }}
              />
            )}
          </Head>
          <div id="scrollTo-Everything" />
          {authState.type === 'authenticated' && basketError && (
            <Toast
              message={`Ooops! We ${BasketErrors[basketError.error]}, please refresh the page`}
            />
          )}
          <VendorHeader
            images={vendorImages}
            logo={vendor.logo}
            title={vendor.companyName}
            keywords={vendor.keywords}
            rating={vendor.rating}
            totalRatings={vendor.totalRatings}
            currency={vendor.currency}
            minimumOrderValueExTax={minOrderValueExTax}
            minimumNoticePeriod={minimumNoticePeriod}
            descriptionFull={vendor.descriptionFull}
            quote={vendor.quote}
            sourcing={vendor.sourcing}
            taxAcronym={taxAcronym}
            externalUrlData={vendor.externalUrlData}
          />
          {!showMultipleBaskets ? (
            <MuiBox padding={1} sx={{ borderColor: 'divider' }} borderTop={1} borderBottom={1}>
              {!isInitialLoad ? (
                <InputBar
                  disabled={isEditingExistingOrder || editingStandingOrderTemplate}
                  orderSelectedSettings={orderSelectedSettings}
                  hideFields={{ date: editingStandingOrderTemplate }}
                  onValueChange={(values) => updateUserState(values)}
                  availableVendorLocations={availableVendorLocations}
                  vendorId={vendor.id}
                  modalOpen={orderDetailsModalState.open}
                  modalIsLoading={isDataUpdating}
                  setModalOpen={(value) => setOrderDetailsModalState({ open: value })}
                  onConfirm={(newState) => {
                    updateUserState(newState)
                    handleUserBasketSettingsConfirm(newState)
                  }}
                />
              ) : (
                <Skeleton height={40} />
              )}
            </MuiBox>
          ) : null}

          <Stack direction="row" spacing={1} paddingY={2}>
            <MuiBox width={{ xs: '100%', sm: '60%', md: '70%' }}>
              <GmFiltersStoreProvider useServerSideItems={useServerSideItems}>
                <MenuDisplay
                  stickyFiltersTopValue={navHeight}
                  initialFilters={
                    router.isReady
                      ? {
                          allergens: searchParams?.get('allergens') ?? undefined,
                          dietaries: searchParams?.get('dietaries') ?? undefined,
                          category: searchParams?.get('category') ?? undefined,
                          goals: searchParams?.get('goals') ?? undefined,
                          itemName: searchParams?.get('itemName') ?? undefined,
                        }
                      : null
                  }
                  taxAcronym={taxAcronym}
                  vendor={vendor}
                  itemData={itemData}
                  useServerSideItems={useServerSideItems}
                  useVirtualisedList={useVirtualisedList}
                  onAddItemToBasket={(item) => {
                    if (isEditingExistingOrder) {
                      existingOrderState.addNewItemToOrder({
                        liveItemId: item.item._id,
                        variationId: item.item.variationId,
                        name: item.item.name,
                        priceExTax: item.item.priceCustomerFacingExTax,
                        priceIncTax: item.item.priceCustomerFacing,
                        qty: item.qty,
                        taxRate: item.item.taxRate,
                        note: item.note ?? undefined,
                        options:
                          item.options?.map((option) => ({
                            id: option.optionItem._id,
                            name: option.optionItem.item.name,
                            optionCategoryId: option.option._id,
                            priceExTax: option.optionItem.priceCustomerFacingExTax ?? 0,
                            priceIncTax: option.optionItem.priceCustomerFacing,
                            qty: option.qty * item.qty,
                            taxRate: option.optionItem.taxRate,
                          })) ?? [],
                      })
                    } else {
                      basketData
                        ? basketState.addItemToBasket(basketData, item)
                        : handleCreateBasket(item)
                    }
                  }}
                />
              </GmFiltersStoreProvider>
            </MuiBox>
            <MuiBox width={{ xs: '100%', sm: '40%', md: '30%' }}>
              <ConsolidatedBasketWrapper isMobile={isMobileScreen} open={mobileBasketOpen}>
                <Stack
                  direction="column"
                  position="sticky"
                  paddingTop={1}
                  top={!isMobileScreen ? navHeight : 0}
                >
                  <NewDraftButton
                    onClick={handleCreateBasket}
                    disabled={loadingBasketData}
                    confirmationText={
                      !showMultipleBaskets && basketData
                        ? 'Your current draft will be lost, are you sure?'
                        : undefined
                    }
                  />

                  <Stack direction="column" boxShadow={isMobileScreen ? 0 : 8}>
                    {showMultipleBaskets &&
                    !isMobileScreen &&
                    !isEditingExistingOrder &&
                    !isEditingStandingOrder
                      ? unselectedBaskets.map((basketOption) => {
                          return (
                            <MuiBox
                              onClick={() => {
                                setSelectedBasket(basketOption.id)
                              }}
                              key={basketOption.id}
                              sx={{
                                cursor: 'pointer',
                                borderTopRightRadius: '4px',
                                borderTopLeftRadius: '4px',
                                overflow: 'hidden',
                                paddingBottom: 0.5,
                              }}
                            >
                              <ConsolidatedBasketHeader
                                variant="inactive"
                                friendlyId={basketOption.friendlyId ?? ''}
                                copyFriendlyId={() => {
                                  const link = generateBasketLink(basketOption?.id ?? '')
                                  navigator.clipboard.writeText(link)
                                  setSnackbarType('linkCopied')
                                }}
                                disableDelete={basketSelectionState.loading}
                                onDelete={() => deleteBasket(basketOption.id)}
                                basketHeaderName={basketOption.customName ?? 'Draft order'}
                              />
                            </MuiBox>
                          )
                        })
                      : null}

                    <ConsolidatedBasket
                      maxHeight={`${basketMaxHeight}px`}
                      id={mappedBasketorOrderDataToCartData.id}
                      loadingNewData={loadingBasketDrawerItems}
                      items={mappedBasketorOrderDataToCartData.items}
                      fees={mappedBasketorOrderDataToCartData.fees}
                      taxRates={mappedBasketorOrderDataToCartData.taxRates}
                      summary={mappedBasketorOrderDataToCartData.summary}
                      taxAcronym={taxAcronym}
                      dataIsUpdating={isDataUpdating}
                      onDeleteClick={(itemId) => {
                        if (basketData)
                          basketState.removeItemFromBasket(basketData, {
                            itemReference: itemId,
                          })
                        else if (isEditingExistingOrder)
                          existingOrderState.removeItemFromOrder({ id: itemId })
                      }}
                      onQuantityChange={(itemId, qty) => {
                        if (basketData)
                          basketState.updateItemQty(basketData, { itemReference: itemId }, qty)
                        else if (isEditingExistingOrder)
                          existingOrderState.adjustItemQuantity({
                            id: itemId,
                            newQty: qty,
                          })
                      }}
                      headerSection={
                        <>
                          <ConsolidatedBasketHeader
                            onClose={isMobileScreen ? () => setMobileBasketOpen(false) : undefined}
                            onDelete={match(selectedBasket)
                              .with({ id: P.string }, (basket) => () => deleteBasket(basket.id))
                              .otherwise(() => undefined)}
                            friendlyId={
                              mappedBasketorOrderDataToCartData.summary.friendlyId ||
                              selectedBasket?.friendlyId ||
                              ''
                            }
                            copyFriendlyId={() => {
                              const linkToCopy = match({
                                isEditingExistingOrder,
                                isEditingStandingOrder,
                              })
                                .with({ isEditingExistingOrder: true }, () =>
                                  generateOrderLink(mappedBasketorOrderDataToCartData.id),
                                )
                                .with({ isEditingStandingOrder: true }, () =>
                                  generateStandingOrderLink(mappedBasketorOrderDataToCartData.id),
                                )
                                .otherwise(() =>
                                  generateBasketLink(
                                    mappedBasketorOrderDataToCartData.id ||
                                      selectedBasket?.id ||
                                      '',
                                  ),
                                )

                              navigator.clipboard.writeText(linkToCopy)
                              setSnackbarType('linkCopied')
                            }}
                            onNameChange={match({
                              isEditingExistingOrder,
                              isEditingStandingOrder,
                              showMultipleBaskets,
                              basketData,
                            })
                              .with(
                                {
                                  isEditingExistingOrder: false,
                                  isEditingStandingOrder: false,
                                  showMultipleBaskets: true,
                                  basketData: P.not(P.nullish),
                                },
                                () => (newName: string) => {
                                  handleSaveBasketName(newName)
                                },
                              )
                              .otherwise(() => undefined)}
                            basketHeaderName={match({
                              isEditingExistingOrder,
                              isEditingStandingOrder,
                            })
                              .with(
                                { isEditingExistingOrder: true },
                                () => existingOrderState.orderData?.customName ?? 'Edit order',
                              )
                              .with({ isEditingStandingOrder: true }, () => 'Standing order')
                              .otherwise(() => basketData?.customName ?? 'Draft order')}
                          />
                          {showMultipleBaskets ? (
                            <InputBar
                              loading={
                                loadingStandingOrderData ||
                                loadingOrderData ||
                                (!basketSelectionState.justCreated && loadingBasketData)
                              }
                              showBasketEmbeddedVersion
                              disabled={isEditingExistingOrder || editingStandingOrderTemplate}
                              orderSelectedSettings={orderSelectedSettings}
                              hideFields={{ date: editingStandingOrderTemplate }}
                              onValueChange={(values) => updateUserState(values)}
                              availableVendorLocations={availableVendorLocations}
                              vendorId={vendor.id}
                              modalOpen={orderDetailsModalState.open}
                              modalIsLoading={isDataUpdating}
                              setModalOpen={(value) => setOrderDetailsModalState({ open: value })}
                              onConfirm={(newState) => {
                                updateUserState(newState)
                                handleUserBasketSettingsConfirm(newState)
                              }}
                            />
                          ) : null}
                        </>
                      }
                      errorSection={
                        <ErrorSection
                          severity={match<{ orderSaveError: boolean }, AlertProps['severity']>({
                            orderSaveError: Boolean(existingOrderState.saveError),
                          })
                            .with({ orderSaveError: true }, () => 'error')
                            .otherwise(() => 'warning')}
                          isUpdating={isDataUpdating}
                          error={match({
                            minSpendNotMet: isEditingExistingOrder
                              ? Boolean(existingOrderState.validationErrors.MINIMUM_SPEND_NOT_MET)
                              : !basketMinimumOrderReached,
                            itemsEditedByVendor: vendorUpdatedItems.length > 0,
                            orderSaveError: Boolean(existingOrderState.saveError),
                            pastEditCutoffError: Boolean(
                              existingOrderState.validationErrors.PAST_EDIT_CUT_OFF,
                            ),
                            invalidPaymentMethod: Boolean(
                              existingOrderState.validationErrors.INVALID_PAYMENT_METHOD,
                            ),
                          })
                            .with({ minSpendNotMet: true }, () => {
                              const minimumSpendLeft =
                                existingOrderState.validationErrors.MINIMUM_SPEND_NOT_MET
                                  ?.amountRequired ?? numberFormat(basketMinSpendRemaining / 100)

                              return (
                                <>
                                  You need to spend {getCurrencySymbol(vendor.currency)}
                                  {minimumSpendLeft} (ex {taxAcronym}) more to checkout.
                                </>
                              )
                            })
                            .with({ invalidPaymentMethod: true }, () => (
                              <>Orders paid for by card cannot currently be edited</>
                            ))
                            .with({ pastEditCutoffError: true }, () => (
                              <>
                                Changes can no longer be made to this order. Please contact support
                                for further assistance.
                              </>
                            ))
                            .with({ orderSaveError: true }, () => (
                              <>
                                Unable to save changes to your order:{' '}
                                {existingOrderState.saveError?.message}
                              </>
                            ))
                            .with({ itemsEditedByVendor: true }, () => (
                              <>
                                Some items have been edited, please acknowledge them before
                                proceeding.
                                <Button
                                  color="warning"
                                  onClick={() => {
                                    if (basketData) {
                                      vendorUpdatedItems.map((item) =>
                                        basketState.updateItemInBasket(
                                          basketData,
                                          { itemReference: item.id },
                                          {
                                            acknowledgedAt: new Date(),
                                          },
                                        ),
                                      )
                                    }
                                  }}
                                >
                                  Keep
                                </Button>
                                <Button
                                  color="warning"
                                  onClick={() => {
                                    if (basketData) {
                                      vendorUpdatedItems.map((item) =>
                                        basketState.removeItemFromBasket(basketData, {
                                          itemReference: item.id,
                                        }),
                                      )
                                    }
                                  }}
                                >
                                  Remove
                                </Button>
                              </>
                            ))
                            .otherwise(() => undefined)}
                        />
                      }
                      footerSection={match({
                        isEditingExistingOrder,
                        isEditingStandingOrder,
                      })
                        .with({ isEditingExistingOrder: true }, () => (
                          <OrderFooter
                            disableSubmission={!existingOrderState.canSaveChanges}
                            loading={existingOrderState.saving}
                            save={existingOrderState.saveOrderChanges}
                          />
                        ))
                        .with({ isEditingStandingOrder: true }, () => (
                          <StandingOrderFooter
                            disableSubmission={!basketMinimumOrderReached}
                            save={() => basketState.saveStandingOrderChanges()}
                          />
                        ))
                        .otherwise(() => (
                          <BasketFooter
                            disableSubmission={!basketMinimumOrderReached}
                            checkout={() => (basketData ? checkoutCallback(basketData.id) : null)}
                          />
                        ))}
                    />
                  </Stack>
                </Stack>
              </ConsolidatedBasketWrapper>
              {basketData && editItemModal.type === 'displayed' && (
                <MenuItemModal
                  taxAcronym={taxAcronym}
                  mode="edit"
                  menuItem={editItemModal.menuItem}
                  basketItem={editItemModal.basketItem}
                  vendor={vendor}
                  onConfirmClick={({ qty, options }) => {
                    const basketInput = { qty, options }
                    basketState.updateItemInBasket(
                      basketData,
                      editItemModal.basketItem,
                      basketInput,
                    )
                  }}
                  onClose={() => setEditItemModal({ type: 'hidden' })}
                />
              )}
            </MuiBox>
          </Stack>
        </MuiBox>
      </Container>

      <Snackbar
        open={Boolean(snackbarType)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={match(snackbarType)
          .with('linkCopied', () => 2000)
          .with('standingOrderSaved', () => 2000)
          .otherwise(() => undefined)}
        sx={{ marginBottom: '60px' }}
        onClose={(_e: React.SyntheticEvent | Event, reason?: string) => {
          if (reason === 'clickaway') {
            return
          }
          setSnackbarType(null)
        }}
      >
        <Stack>
          {match(snackbarType)
            .with('standingOrderSaved', () => (
              <Alert severity="success" variant="filled" sx={{ zIndex: 4 }}>
                <AlertTitle sx={{ marginBottom: 0 }}>Standing order saved</AlertTitle>
              </Alert>
            ))
            .with('linkCopied', () => (
              <Alert severity="success" variant="filled" sx={{ zIndex: 4 }}>
                <AlertTitle sx={{ marginBottom: 0 }}>Link copied to clipboard</AlertTitle>
              </Alert>
            ))
            .otherwise(() => null)}
        </Stack>
      </Snackbar>
    </div>
  )
}

// @ts-expect-error unknown
const Component: React.FC<AppProps> = withRouter(withGm(MenuPage, false))
Component.displayName = 'GM'

type Params = {
  permalink: string
  lang: string
  domain: string
}

export const getStaticPaths: GetStaticPaths<Params> = () => {
  const paths = (tenants as TenantFromPrebuildFragment[]).reduce<{ params: Params }[]>(
    (acc, tenant) => {
      const permalinks = tenant.features?.prerenderMarketplaceMenu?.permalinks || []
      if (permalinks.length === 0) return acc

      const validLocales = SUPPORTED_LOCALES.filter((locale) =>
        tenant.countries.some((country) => locale.endsWith(`-${country}`)),
      )

      validLocales.forEach((locale) => {
        permalinks.forEach((permalink) => {
          acc.push({
            params: {
              domain: tenant.domain,
              lang: locale,
              permalink,
            },
          })
        })
      })

      return acc
    },
    [],
  )

  return {
    paths,
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps<
  { vendorDetails?: GetVendorQuery['vendor'] },
  Params
> = async ({ params }) => {
  if (!params) throw new Error('Params not found in getStaticProps')
  const tenant = getTenantFromDomain(params.domain)
  if (!tenant) throw new Error('Tenant not found in getStaticProps')
  const apolloClient = apolloInit()

  let useServerSideItems = false
  let useVirtualisedList = false
  if (process.env.EDGE_CONFIG) {
    // const ssrVendors = [{
    //   "domain": "eatfirst.caterdesk.dev",
    //   "permalinks": ["box-fresh-pantry"]
    // }]
    const ssrVendors = (await get('ssrVendors')) as {
      domain: string
      permalinks: string[]
    }[]

    if (
      ssrVendors.some(
        (vendor) =>
          vendor.domain === params.domain &&
          (vendor.permalinks.includes(params.permalink) || vendor.permalinks.length === 0),
      )
    ) {
      useServerSideItems = true
    }

    // const virtualisedListVendors = [{
    //   "domain": "eatfirst.caterdesk.dev",
    //   "permalinks": ["box-fresh-pantry"]
    // }]
    const virtualisedListVendors = (await get('virtualisedListVendors')) as {
      domain: string
      permalinks: string[]
    }[]

    if (
      virtualisedListVendors.some(
        (vendor) =>
          vendor.domain === params.domain &&
          (vendor.permalinks.includes(params.permalink) || vendor.permalinks.length === 0),
      )
    ) {
      useVirtualisedList = true
    }
  }

  const { data } = await apolloClient.query<GetVendorQuery, GetVendorQueryVariables>({
    query: GetVendorDocument,
    variables: { permalink: params.permalink, maxRating: 5, tenantId: tenant.id },
  })

  if (!useServerSideItems) {
    return {
      props: {
        ssg: 1,
        vendorDetails: data?.vendor,
        domain: params.domain,
        lang: params.lang,
      },
      revalidate: 60 * 60 * 2, // 2 hours
    }
  }

  if (!data?.vendor) {
    throw new Error('Vendor not found')
  }

  const sortedCategoryIds = [...data.vendor.categories]
    .sort((a, b) => {
      if (!a || a?.position === null) return 1
      if (!b || b?.position === null) return -1
      return a?.position - b?.position
    })
    .map((category) => (category ? Number(category.id) : null))
    .filter((id) => id !== null)

  const { data: itemData } = await apolloClient.query<
    GetVendorAvailabilityQuery,
    GetVendorAvailabilityQueryVariables
  >({
    query: GetVendorAvailabilityDocument,
    variables: {
      vendorId: data?.vendor.id,
      limit: 10000,
      offset: 0,
      filters: {
        ignoreDeliveryDate: true,
        ignorePrivateItems: true,
      },
      sorting: {
        categorySortPriority: sortedCategoryIds,
      },
    },
  })

  return {
    props: {
      ssg: 1,
      vendorDetails: data?.vendor,
      domain: params.domain,
      lang: params.lang,
      itemData: itemData,
      useServerSideItems,
      useVirtualisedList,
    },
    revalidate: 60 * 60 * 2, // 2 hours
  }
}

export default Component
