import { Box, Collapse, Divider, IconButton, Stack, Typography } from '@mui/material'
import { Item } from './index'
import CommentIcon from '@mui/icons-material/Comment'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import QuantitySelect from '@/components/QuantitySelect'
import React from 'react'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'

type Props = {
  onDeleteClick: (itemId: string) => void
  onQuantityChange: (itemId: string, quantity: number) => void
} & Item

const BasketItem: React.FC<Props> = ({ onDeleteClick, onQuantityChange, ...item }) => {
  const { id, itemOptions, price, name, note, minimumQuantity, quantity, updatedByVendor } = item
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandOptionOrNoteClick = () => {
    setExpanded(!expanded)
  }

  const handleOnQuantityChange = (newQuantityValue: number) => {
    onQuantityChange(id, newQuantityValue)
  }

  const hasOptionOrNote = itemOptions.length > 0 || note.length > 0
  return (
    <Stack spacing={1} mb={1}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Typography color={!updatedByVendor ? 'secondary.main' : 'warning.main'} variant="body2">
          {name}
        </Typography>
        <Typography variant="body2">{price}</Typography>
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {itemOptions?.map((option, index) => {
          const { name, priceExTax } = option
          const key = `${name}-${index}`
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              key={key}
            >
              <Box display="flex" alignItems="center">
                <SubdirectoryArrowRightIcon sx={{ transform: 'scale(0.5)' }} />
                <Typography variant="caption" sx={{ marginLeft: 1 }}>
                  {name}
                </Typography>
              </Box>
              <Typography variant="caption">{priceExTax}</Typography>
            </Stack>
          )
        })}
        {note && (
          <Stack direction={'row'} alignItems={'center'}>
            <CommentIcon sx={{ transform: 'scale(0.5)' }} />{' '}
            <Typography paddingLeft={1} variant="caption">
              {note}
            </Typography>
          </Stack>
        )}
      </Collapse>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <QuantitySelect
          onChange={handleOnQuantityChange}
          max={!item.canUpdateQty ? quantity : 2000}
          min={!item.canUpdateQty ? quantity : minimumQuantity}
          size="small"
          quantity={quantity}
          disabled={!item.canUpdateQty}
        />
        {hasOptionOrNote && (
          <IconButton
            sx={{ transform: `scale(0.75) ${expanded ? 'rotate(180deg)' : 'rotate(0deg)'}` }}
            onClick={handleExpandOptionOrNoteClick}
          >
            <ExpandCircleDownIcon />
          </IconButton>
        )}
        {onDeleteClick && (
          <IconButton
            disabled={!item.canDelete}
            style={{ margin: 0, padding: 0 }}
            onClick={() => onDeleteClick(id)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Divider />
    </Stack>
  )
}

export default BasketItem
