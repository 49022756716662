import { format } from 'date-fns'
import { MenuVendor } from '../../../domain/menu-vendor'
import { ValidUserBasketSettings } from '../../../domain/user-basket-settings'
import {
  CreateBasketMutation,
  GmOrderType,
  GmServiceFormatInput,
  useCreateBasketMutation as useGeneratedMutation,
} from '@/generated/graphql'
import { basketLocationToInputFormat } from '@/components/page-specific/gm/helpers/addresses'
import { AddItemPayload } from './add-item-mutation'
import { getTenantFromWindow } from '@/helpers/tenants'
import { useUtmData } from '@/hooks/useUtmData'

export const useCreateBasketMutation = () => {
  const [run, result] = useGeneratedMutation()
  const utmData = useUtmData()

  const error =
    result.error ||
    (result.called && !result.loading && !result.data?.basket ? 'Unknown error' : null)

  const createBasket = ({
    vendor,
    vendorLocationId,
    availabilityId,
    itemPayload,
    account,
    userBasketSettings,
    onCompleted,
  }: {
    vendor: Pick<MenuVendor, 'id' | 'companyName' | 'isPantry'>
    vendorLocationId: string | null
    availabilityId: string
    itemPayload?: AddItemPayload
    account: { id: string; name: string } | null
    userBasketSettings: ValidUserBasketSettings
    onCompleted?: (data: CreateBasketMutation) => void
  }) => {
    void run({
      variables: {
        availabilityId,
        basketPayload: {
          items: itemPayload
            ? [
                {
                  acknowledgedAt: new Date(),
                  item: itemPayload.item._id,
                  note: itemPayload.note,
                  options: (itemPayload.options || []).map(({ option, optionItem, qty }) => ({
                    categoryId: option._id,
                    item: optionItem.item._id,
                    optionItemId: optionItem._id,
                    qty,
                  })),
                  qty: itemPayload.qty,
                },
              ]
            : [],
          settings: {
            ...(account?.id && {
              account: {
                id: Number(account.id),
                name: account.name,
                locationId:
                  userBasketSettings.location.__typename === 'AccountLocation'
                    ? Number(userBasketSettings.location.id)
                    : null,
              },
            }),
            location: basketLocationToInputFormat(userBasketSettings.location),
            billingAddressSameAsDelivery: true,
            deliveryDate: format(new Date(userBasketSettings.date), 'yyyy-MM-dd'),
            deliveryTimeSlot: userBasketSettings.time,
            headCount: userBasketSettings.headCount,
            vendor: {
              id: Number(vendor.id),
              name: vendor.companyName,
              locationId: Number(vendorLocationId),
              url: userBasketSettings.vendor.url,
              imageUrl: userBasketSettings.vendor.imageUrl,
            },
            tenantId: getTenantFromWindow()?.id,
            orderType: vendor.isPantry ? GmOrderType.PantryFill : undefined,
          },
          options: {
            format: GmServiceFormatInput.Individual,
            rep: false,
            isHighRisk: false,
            addons: {
              napkins: false,
              plates: false,
              service: false,
            },
            notes: {
              cart: '',
              account: '',
              vendor: '',
            },
          },
          utmData,
        },
      },
      onCompleted,
    })
  }

  return {
    run: createBasket,
    loading: result.loading,
    basket: result.data?.basket,
    error,
  }
}
