import { Badge, Box, Divider, Stack, ToggleButton, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { MenuItemsSection } from './menu-items-section'
import LoadingIndicator from '@/components/LoadingIndicator'
import CategoryTabs from './category-tabs'
import ModalFilters from './modal-filters'
import { Tune } from '@mui/icons-material'
import MenuItemSearch from './menu-item-search'
import useGmFiltersStore from '../../helpers/gmFiltersStore'
import { useMenuPageState } from '../states/menu-page'
import { useAuthState } from '../states/auth'
import { MenuVendor } from '../../domain/menu-vendor'
import { GetVendorAvailabilityQuery } from '@/generated/graphql'
import { AddItemPayload } from '../states/menu-page/add-item-mutation'
import { match } from 'ts-pattern'
import { useInView } from 'react-intersection-observer'
import { EVERYTHING_FILTER_CATEGORY } from '@/components/page-specific/gm/domain/menu-category-filter'

type Props = {
  useServerSideItems?: boolean
  useVirtualisedList?: boolean
  vendor: MenuVendor
  itemData?: GetVendorAvailabilityQuery
  taxAcronym: string
  stickyFiltersTopValue: number
  initialFilters: {
    category?: string
    goals?: string
    allergens?: string
    dietaries?: string
    itemName?: string
  } | null
  onAddItemToBasket: (newItem: AddItemPayload) => void
}

const MenuDisplay: React.FC<Props> = ({
  taxAcronym,
  useServerSideItems,
  useVirtualisedList,
  vendor,
  itemData,
  initialFilters,
  stickyFiltersTopValue,
  onAddItemToBasket,
}) => {
  const authState = useAuthState()
  const { ref: loadMoreRef, entry } = useInView()
  const menuItemsState = useMenuPageState({
    vendor,
    itemData,
    useServerSideItems,
    authState,
  })

  const {
    itemName,
    selectedCategory,
    initialised,
    setItemName,
    clearFilters,
    initialiseFilters,
    goals,
    allergens,
    dietaries,
  } = useGmFiltersStore()

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const openFilterMenu = Boolean(menuAnchorEl)

  const itemsStateLoaded = menuItemsState.type === 'loaded'
  const selectedFiltersBadgeCount = goals.length + dietaries.length + allergens.length
  const numberOfCategories = itemsStateLoaded
    ? menuItemsState.availability.menuCategories.length
    : 0

  const loadMore = itemsStateLoaded ? menuItemsState.loadMore : null
  const loadingMore = itemsStateLoaded ? menuItemsState.loadingMore : null
  const availability = itemsStateLoaded ? menuItemsState.availability : null
  const intersectionTime = useRef<number>(0)

  const setItemNameParam = (itemName: string) => {
    if (!initialised) return
    setItemName(itemName)
  }

  const handleClearAll = () => {
    clearFilters()
  }

  const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleFilterMenuClose = () => {
    setMenuAnchorEl(null)
  }

  useEffect(() => {
    if (
      !entry?.isIntersecting ||
      entry.time === intersectionTime.current ||
      loadingMore ||
      !loadMore ||
      numberOfCategories < 1
    )
      return
    intersectionTime.current = entry.time
    loadMore()
  }, [entry, menuItemsState.type, loadMore, loadingMore, numberOfCategories])

  useEffect(() => {
    if (initialised || !initialFilters || !itemsStateLoaded) return

    const category = availability?.filterCategories.find((c) => c.name === initialFilters.category)

    const goals =
      availability?.filterSections.goals.filter((g) =>
        initialFilters.goals?.split(',')?.includes(g.value),
      ) || []
    const dietaries =
      availability?.filterSections.dietaries.filter((g) =>
        initialFilters.dietaries?.split(',')?.includes(g.value),
      ) || []
    const allergens =
      availability?.filterSections.allergens.filter((g) =>
        initialFilters.allergens?.split(',')?.includes(g.value),
      ) || []

    initialiseFilters({
      itemName: initialFilters.itemName || '',
      category: category || EVERYTHING_FILTER_CATEGORY,
      goals,
      dietaries,
      allergens,
    })
  }, [itemsStateLoaded, initialised, initialFilters, initialiseFilters, availability])

  return (
    <>
      <Box
        position="sticky"
        paddingTop={1}
        top={stickyFiltersTopValue}
        zIndex={2}
        bgcolor="background.default"
      >
        <Stack direction="row" alignItems="center">
          <MenuItemSearch onItemNameChange={setItemNameParam} itemNameSearchValue={itemName} />
          <Badge badgeContent={selectedFiltersBadgeCount} color="primary">
            <ToggleButton
              value="check"
              onClick={handleFilterMenuClick}
              sx={{
                width: '8.5rem',
                height: '56px',
                justifyContent: 'space-between',
                marginLeft: '1rem',
              }}
            >
              <Typography variant="body2">Filters</Typography>
              <Tune />
            </ToggleButton>
          </Badge>
          {itemsStateLoaded ? (
            <ModalFilters
              filterSections={menuItemsState.availability.filterSections}
              anchorEl={menuAnchorEl}
              open={openFilterMenu}
              handleClose={handleFilterMenuClose}
              clearFilters={handleClearAll}
            />
          ) : null}
        </Stack>
        {itemsStateLoaded ? (
          <Stack spacing={2} marginY={2}>
            <CategoryTabs
              categories={menuItemsState.availability.filterCategories}
              selectedCategory={selectedCategory}
            />
            <Divider
              sx={{
                boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.1)',
              }}
            />
          </Stack>
        ) : null}
      </Box>
      <Box sx={{ marginTop: 5, width: '100%' }}>
        {match(menuItemsState)
          .with({ type: 'loading' }, () => (
            <Stack alignItems="center" height="60vh">
              <LoadingIndicator />
            </Stack>
          ))
          .with({ type: 'loaded' }, ({ availability, reloading, hasMore }) => (
            <>
              <MenuItemsSection
                reloading={reloading}
                taxAcronym={taxAcronym}
                vendor={vendor}
                categories={availability.menuCategories}
                onAddItemToBasketClick={(item) => {
                  onAddItemToBasket(item)
                }}
                loadingMore={Boolean(loadingMore)}
                useVirtualisedList={useVirtualisedList}
              />
              {!useServerSideItems && !loadingMore && hasMore ? (
                <div style={{ position: 'relative', top: '-2000px' }} ref={loadMoreRef} />
              ) : null}
            </>
          ))
          .exhaustive()}
      </Box>
    </>
  )
}

export default MenuDisplay
