import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { TextExtraExtraSmall, TextExtraSmall } from '@caterdesk/ui--typography'
import { MenuVendor } from '../../../domain/menu-vendor'
import { MenuItem } from '../../../domain/menu-item'
import { main } from '@caterdesk/ui--theme'
import Box from '@caterdesk/ui--box'
import Stack from '@caterdesk/ui--stack'
import { SectionTitle } from './index.styles'
import useAllergenInfoText from '../../../helpers/useAllergenInfoText'
import DietaryListMui from '@/components/DietaryListMui'
import { DialogContent } from '@mui/material'

type Props = {
  vendor: MenuVendor
  item: MenuItem
}

const Markdown = styled(ReactMarkdown)`
  p {
    margin: 0;
  }
`

export const IngredientsTab: React.FC<Props> = ({ vendor, item }) => {
  const allergenInfoText = useAllergenInfoText()

  return (
    <DialogContent>
      <Stack direction="vertical" spacing={16} alignItems="start">
        <SectionTitle>All information provided by {vendor.companyName}</SectionTitle>

        {item.ingredients && (
          <Box>
            <SectionTitle color={main.colors.text_heading}>Ingredients list</SectionTitle>
            <Box padding={{ y: 4 }}>
              <TextExtraSmall style={{ lineHeight: '24px' }}>
                <Markdown>{item.ingredients}</Markdown>
              </TextExtraSmall>
            </Box>
          </Box>
        )}

        {item.dietaries.length > 0 && (
          <Box>
            <SectionTitle color={main.colors.text_heading}>Dietary flags</SectionTitle>
            <Box padding={{ y: 4 }}>
              <DietaryListMui dietaries={item.dietaries} allergens={[]} showLabel />
            </Box>
          </Box>
        )}

        {item.allergens.length > 0 && (
          <Box>
            <SectionTitle color={main.colors.text_heading}>Allergens</SectionTitle>
            <Box padding={{ y: 4 }}>
              <DietaryListMui
                isComboItem={Boolean(item.isCombo)}
                dietaries={[]}
                allergens={item.allergens}
                subAllergens={item.subAllergens}
                showLabel
              />
            </Box>
          </Box>
        )}

        <Box>
          <TextExtraExtraSmall style={{ lineHeight: '20px' }}>
            {allergenInfoText}
          </TextExtraExtraSmall>
        </Box>
      </Stack>
    </DialogContent>
  )
}
