import { GetVendorQuery, Language } from '@/generated/graphql'
import { populateFieldsWithTranslations } from '@caterdesk/utils--translations'
import { validateMenuVendor } from '../../domain/menu-vendor'
import { useMemo } from 'react'
import { useAppError } from '../../utils/errors'

type Props = {
  language: Language
  vendorDetails: GetVendorQuery['vendor']
}

export const useMenuPageVendor = ({ vendorDetails, language }: Props) => {
  const { throwAppError } = useAppError()
  const vendorTranslated = useMemo(
    () => (vendorDetails ? populateFieldsWithTranslations(vendorDetails, language) : vendorDetails),
    [vendorDetails, language],
  )

  const vendorValidation = useMemo(
    () => (vendorTranslated ? validateMenuVendor(vendorTranslated) : ({ type: 'idle' } as const)),
    [vendorTranslated],
  )

  if (vendorValidation.type === 'idle')
    return throwAppError({
      type: 'COULD_NOT_FETCH_VENDOR',
      permalink: vendorDetails?.permalink ?? '',
      cause: 'Could not load vendor details',
    })
  if (vendorValidation.type === 'error') return throwAppError(vendorValidation.error)

  return {
    vendor: vendorValidation.vendor,
  }
}
