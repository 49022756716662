import { graphql } from '@/generated/gql'
import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

const gmMenuPageUseExistingOrderGetGmOrderQuery = graphql(`
  query GmMenuPageUseExistingOrder_GetGmOrderQuery($gmOrderId: ID!) {
    gmOrder(id: $gmOrderId) {
      id
      orderId
      vendor {
        id
      }
      orderItems {
        items {
          isCustom
          id
        }
      }
      customName
      ...BasketUiCompatibleGmOrder_OrderDataFragment
      ...UseExistingOrderItems_OrderDataFragment
      ...UseInputRelatedOrderData_OrderDataFragment
      ...UseExistingOrderMutations_OrderDataFragment
      ...UseOrderPaymentType_OrderDataFragment
      ...OrderBasketMapping_OrderDataFragment
    }
  }
`)

type Props = {
  orderId?: string
  vendorId?: string
}

export const useExistingOrderQueries = ({ orderId, vendorId }: Props) => {
  const [getOrderData, { data, loading, called }] = useLazyQuery(
    gmMenuPageUseExistingOrderGetGmOrderQuery,
  )

  useEffect(() => {
    if (!orderId || !vendorId) return
    getOrderData({ variables: { gmOrderId: orderId } })
  }, [orderId, getOrderData, vendorId])

  const orderData = data?.gmOrder && data.gmOrder.vendor.id === vendorId ? data.gmOrder : null

  return {
    loading,
    called,
    orderData,
  }
}
