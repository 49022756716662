import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetBasketQuery } from '../menu-page/get-basket-query'
import { populateBasketTranslationFields } from '../../../helpers/translations'
import { useGetStandingOrderQuery } from '../menu-page/get-standing-order-query'
import { BasketFragment, GmMenuStandingOrderDataFragment, Language } from '@/generated/graphql'
import { AddItemToBasket, useAddItemMutation } from '../menu-page/add-item-mutation'
import { useUpdateItemMutation } from '../menu-page/update-item-mutation'
import { useRemoveItemMutation } from '../menu-page/remove-item-mutation'
import { useSaveStandingOrderMutation } from '../menu-page/save-standing-order-mutation'
import { useUpdateItemQtyMutation } from '../menu-page/update-item-qty-mutation'
import { useUpdateBasketMutation } from '../menu-page/update-basket-mutation'
import { BasketErrors } from '../../../types/enums/Errors.enum'
import { useCounter } from 'react-use'

type BasketErrorType = keyof typeof BasketErrors

type Props = {
  basketId: string | null
  standingOrderId: string | null
  language: Language
  deliveryFee?: number
  minOrderValueExTax: number | null
  onBasketDataChange: (newBasketData: BasketFragment) => void
}

export const useSelectedBasketState = ({
  basketId,
  standingOrderId,
  language,
  deliveryFee,
  minOrderValueExTax,
  onBasketDataChange,
}: Props) => {
  const [newItemCount, newItemCountState] = useCounter(0)
  const [basketError, setBasketError] = useState<{
    basketId: string
    error: BasketErrorType
  } | null>(null)
  const {
    run: runGetBasket,
    basket: fetchedBasket,
    loading: loadingBasket,
    ...getBasketQuery
  } = useGetBasketQuery()
  const {
    run: runGetStandingOrder,
    standingOrder: fetchedStandingOrder,
    loading: loadingStandingOrder,
    ...getStandingOrderQuery
  } = useGetStandingOrderQuery()

  const updateBasketMutation = useUpdateBasketMutation()

  const isValidStandingOrder = fetchedStandingOrder && fetchedStandingOrder._id === standingOrderId
  const rawBasketData = isValidStandingOrder
    ? fetchedStandingOrder?.standingOrderTemplate
    : fetchedBasket

  const basket:
    | (BasketFragment & { standingOrderSettings?: GmMenuStandingOrderDataFragment })
    | null = useMemo(() => {
    const basketData =
      (rawBasketData && basketId === rawBasketData.id) || (rawBasketData && isValidStandingOrder)
        ? populateBasketTranslationFields(rawBasketData, language)
        : null
    if (!basketData) return null
    return Object.assign(basketData, { standingOrderSettings: fetchedStandingOrder ?? undefined })
  }, [basketId, rawBasketData, language, isValidStandingOrder, fetchedStandingOrder])

  const editSessionId = basket?.editSessionId

  const addItemMutation = useAddItemMutation(
    deliveryFee,
    Boolean(isValidStandingOrder),
    editSessionId,
  )
  const updateItemMutation = useUpdateItemMutation(
    deliveryFee,
    Boolean(isValidStandingOrder),
    editSessionId,
  )
  const updateItemQtyMutation = useUpdateItemQtyMutation(
    deliveryFee,
    Boolean(isValidStandingOrder),
    editSessionId,
  )
  const removeItemMutation = useRemoveItemMutation(
    deliveryFee,
    Boolean(isValidStandingOrder),
    editSessionId,
  )
  const saveStandingOrderMutation = useSaveStandingOrderMutation(basket?.id, editSessionId)

  const minOrderValue = minOrderValueExTax ?? 0
  const minimumOrderReached = (basket?.subtotalBreakdown.subtotalExTax ?? 0) >= minOrderValue
  const minSpendRemaining = basket
    ? minOrderValue - (basket.subtotalBreakdown.subtotalExTax ?? 0)
    : 0

  const addItem: AddItemToBasket = useCallback(
    (basket, payload) => {
      newItemCountState.inc()
      setTimeout(() => newItemCountState.dec(), 2000)
      addItemMutation.run(basket, payload)
    },
    [addItemMutation, newItemCountState],
  )

  useEffect(() => {
    if (!basketId) return
    setBasketError(null)
    void runGetBasket(basketId)
  }, [basketId, runGetBasket])

  useEffect(() => {
    if (!standingOrderId) return
    void runGetStandingOrder(standingOrderId)
  }, [standingOrderId, runGetStandingOrder])

  useEffect(() => {
    if (!basketId || basketError) return
    if (getBasketQuery.error) setBasketError({ basketId, error: 'COULD_NOT_FETCH_BASKET' })
    if (updateItemQtyMutation.error || saveStandingOrderMutation.error)
      setBasketError({ basketId, error: 'COULD_NOT_UPDATE_BASKET' })
    if (addItemMutation.error) setBasketError({ basketId, error: 'COULD_NOT_ADD_ITEM_TO_BASKET' })
    if (updateItemMutation.error)
      setBasketError({ basketId, error: 'COULD_NOT_UPDATE_ITEM_IN_BASKET' })
    if (updateItemQtyMutation.error)
      setBasketError({ basketId, error: 'COULD_NOT_UPDATE_ITEM_QTY_IN_BASKET' })
    if (removeItemMutation.error)
      setBasketError({ basketId, error: 'COULD_NOT_REMOVE_ITEM_FROM_BASKET' })
    if (getStandingOrderQuery.error) setBasketError({ basketId, error: 'COULD_NOT_FETCH_BASKET' })
  }, [
    getBasketQuery,
    getStandingOrderQuery,
    updateBasketMutation,
    addItemMutation,
    updateItemMutation,
    updateItemQtyMutation,
    removeItemMutation,
    saveStandingOrderMutation,
    basketId,
    basketError,
  ])

  useEffect(() => {
    if (!basket) return
    onBasketDataChange(basket)
  }, [basket, onBasketDataChange])

  return {
    basket,
    basketError,
    minimumOrderReached,
    minSpendRemaining,
    newItemJustAdded: newItemCount > 0,
    updating: [
      updateBasketMutation,
      addItemMutation,
      updateItemMutation,
      updateItemQtyMutation,
      removeItemMutation,
      saveStandingOrderMutation,
    ].some((r) => r.loading),
    standingOrderUpdated: saveStandingOrderMutation.updated,
    saveStandingOrderChanges: saveStandingOrderMutation.run,
    updateItemInBasket: updateItemMutation.run,
    updateBasket: updateBasketMutation.run,
    updateItemQty: updateItemQtyMutation.run,
    removeItemFromBasket: removeItemMutation.run,
    addItemToBasket: addItem,
    loading:
      loadingStandingOrder ||
      loadingBasket ||
      (!basketError && Boolean(basketId && basketId !== basket?.id)),
    called: getBasketQuery.called || getStandingOrderQuery.called,
  }
}
