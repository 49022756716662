import { useExistingOrderQueries } from './useExistingOrderQueries'
import { useBasketDesignedUiCompatibleGmOrderData } from './useBasketDesignedUiCompatibleGmOrderDat'
import { useExistingOrderItems } from './useExistingOrderItems'
import { useInputRelatedVariableOrderData } from './useInputRelatedVariableOrderData'
import { useExistingOrderValidation } from './useExistingOrderValidation'
import { useExistingOrderMutations } from './useExistingOrderMutations'
import { useOrderPaymentType } from './useOrderPaymentType'
import { useEffect, useState } from 'react'

type Props = {
  orderId?: string
  vendorId?: string
  onSuccessfulSave?: (orderData: { orderId: string }) => void
}

export const useExistingOrder = ({ orderId, vendorId, onSuccessfulSave }: Props) => {
  const [loading, setLoading] = useState(false)
  const {
    orderData,
    loading: loadingOrderData,
    called: orderDataCalled,
  } = useExistingOrderQueries({ orderId, vendorId })

  const { orderData: basketDesignedUiCompatibleOrderData } =
    useBasketDesignedUiCompatibleGmOrderData({
      orderData,
    })

  const {
    orderItems,
    orderItemsInternal,
    removedOrderItems,
    changesMade,
    itemQuantity,
    newItemWasJustAdded,
    adjustItemQuantity,
    addNewItemToOrder,
    removeItemFromOrder,
  } = useExistingOrderItems({ orderData })

  const {
    taxRateTotals,
    fees,
    grandTotals,
    subtotalExTaxAsNumber,
    totalIncTaxAsNumber,
    recalculatingFees,
    recalculatingTotals,
    minimumOrderValue,
    userEditCutOffTime,
  } = useInputRelatedVariableOrderData({
    orderData,
    orderItems: changesMade ? orderItemsInternal : null,
  })

  const { paymentMethod } = useOrderPaymentType({ orderData })

  const { canSaveChanges, errors: validationErrors } = useExistingOrderValidation({
    changesMade,
    minimumOrderValue,
    subtotalExTax: subtotalExTaxAsNumber,
    userEditCutOffTime,
    paymentMethod,
  })

  const { saveOrderChanges, saving, saveError } = useExistingOrderMutations({
    orderData,
    removedOrderItems,
    updatedOrderItems: orderItemsInternal,
    updatedOrderTotalIncTax: totalIncTaxAsNumber,
    onSuccessfulSave,
  })

  useEffect(() => {
    if (loadingOrderData) setLoading(true)
  }, [loadingOrderData])

  useEffect(() => {
    if (orderData?.orderItems.items.length !== orderItems.length) return
    setLoading(false)
  }, [loading, orderItems, orderData?.orderItems.items])

  return {
    orderData,
    basketDesignedUiCompatibleOrderData,
    loadingOrderData: loadingOrderData || loading,
    orderItems,
    fees,
    taxRateTotals,
    grandTotals,
    recalculatingFees,
    recalculatingTotals,
    itemQuantity,
    newItemWasJustAdded,
    canSaveChanges: !recalculatingTotals && !recalculatingFees && canSaveChanges,
    validationErrors,
    saving,
    orderDataCalled,
    saveError,
    saveOrderChanges: () => {
      if (!canSaveChanges) return
      saveOrderChanges()
    },
    adjustItemQuantity,
    addNewItemToOrder,
    removeItemFromOrder,
  }
}
