import {
  Stack,
  Chip,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  useTheme,
  ButtonBase,
  alpha,
  Box,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Close, DeleteOutline, Done, Edit } from '@mui/icons-material'

interface ConsolidatedBasketHeaderProps {
  basketHeaderName: string
  friendlyId: string
  variant?: 'active' | 'inactive'
  disableDelete?: boolean
  copyFriendlyId: () => void
  onDelete?: () => void
  onClose?: () => void
  onNameChange?: (newName: string) => void
}

const ConsolidatedBasketHeader: React.FC<ConsolidatedBasketHeaderProps> = ({
  basketHeaderName,
  friendlyId,
  variant = 'active',
  disableDelete,
  copyFriendlyId,
  onDelete,
  onClose,
  onNameChange,
}) => {
  const theme = useTheme()
  const [editValue, setEditValue] = useState(basketHeaderName)
  const [isEditingName, setIsEditingName] = useState(false)

  const handleNameChange = () => {
    if (!/^(?!\s*$).+/.test(editValue) || !onNameChange) return
    onNameChange(editValue)
    setIsEditingName(false)
  }

  useEffect(() => {
    setEditValue(basketHeaderName)
  }, [basketHeaderName])

  return (
    <Stack
      paddingX={2}
      paddingY={1}
      bgcolor={variant === 'active' ? 'primary.main' : 'action.disabledBackground'}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {onNameChange && isEditingName ? (
        <Box
          borderRadius={`${theme.shape.borderRadius}px`}
          width="100%"
          bgcolor={alpha(theme.palette.primary.contrastText, 0.9)}
        >
          <TextField
            fullWidth
            variant="outlined"
            sx={{
              '.MuiInputBase-root': {
                fontSize: theme.typography.body2.fontSize,
              },
            }}
            onKeyDown={(e) => {
              if (e.key !== 'Enter') return
              handleNameChange()
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={editValue === ''} onClick={handleNameChange}>
                    <Done color="action" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size="small"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
        </Box>
      ) : (
        <Stack direction="row" alignItems="center">
          <Typography
            variant="body2"
            color={variant === 'active' ? 'primary.contrastText' : 'text.disabled'}
          >
            {basketHeaderName}
            {onNameChange ? (
              <>
                &nbsp;
                <ButtonBase
                  sx={{ scale: '0.9', position: 'relative', top: '-2px' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsEditingName(true)
                  }}
                >
                  <Edit htmlColor={theme.palette.primary.contrastText} fontSize="small" />
                </ButtonBase>
              </>
            ) : null}
          </Typography>
        </Stack>
      )}

      <Stack direction="row" alignItems="center" spacing={1}>
        {friendlyId && !isEditingName && (
          <Chip
            size="small"
            color="inverted"
            label={
              <Stack direction="row" alignItems="center">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    copyFriendlyId()
                  }}
                  size="small"
                >
                  <ContentCopyIcon sx={{ transform: `scale(0.75)` }} />
                </IconButton>
                <Typography variant="caption">{friendlyId}</Typography>
                {onDelete ? (
                  <IconButton
                    disabled={disableDelete}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      onDelete()
                    }}
                  >
                    <DeleteOutline sx={{ transform: `scale(0.85)` }} />
                  </IconButton>
                ) : null}
              </Stack>
            }
          />
        )}
        {onClose ? (
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        ) : null}
      </Stack>
    </Stack>
  )
}

export default ConsolidatedBasketHeader
