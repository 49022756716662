import React from 'react'
import { LoadingButton } from '@mui/lab'
import { CircularProgress } from '@mui/material'

type Props = {
  disableSubmission: boolean
  loading: boolean
  save: () => void
}
const OrderFooter: React.FC<Props> = ({ disableSubmission, loading, save }) => {
  return (
    <LoadingButton
      color="primary"
      disabled={disableSubmission}
      loading={loading}
      loadingIndicator={<CircularProgress color="inherit" size={16} />}
      onClick={save}
      sx={{ width: '40%' }}
      variant="contained"
    >
      Save
    </LoadingButton>
  )
}

export default OrderFooter
