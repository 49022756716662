import { AddCircleOutline } from '@mui/icons-material'
import { Alert, Button, Chip, Stack } from '@mui/material'
import React, { useState } from 'react'

type Props = {
  onClick: () => void
  disabled: boolean
  confirmationText?: string
}

const NewDraftButton: React.FC<Props> = ({ onClick, disabled, confirmationText }) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  return (
    <Stack alignSelf="flex-end" alignItems="flex-end" spacing={1} paddingBottom={1}>
      <Chip
        sx={{ width: 'fit-content' }}
        size="small"
        label="New draft"
        icon={<AddCircleOutline color="primary" />}
        clickable
        disabled={showConfirmation || disabled}
        onClick={() => {
          if (confirmationText) setShowConfirmation(true)
          else onClick()
        }}
      />
      {showConfirmation ? (
        <Alert severity="warning" variant="outlined">
          <Stack direction="column">
            {confirmationText}
            <Stack width="100%" direction="row" justifyContent="flex-end" spacing={0.5}>
              <Button
                onClick={() => {
                  setShowConfirmation(false)
                }}
                size="small"
                variant="contained"
              >
                Keep current
              </Button>
              <Button
                onClick={() => {
                  setShowConfirmation(false)
                  onClick()
                }}
                size="small"
                variant="outlined"
              >
                Create new
              </Button>
            </Stack>
          </Stack>
        </Alert>
      ) : null}
    </Stack>
  )
}

export default NewDraftButton
