import { LoadingButton } from '@mui/lab'
import { CircularProgress } from '@mui/material'
import React from 'react'

type Props = {
  save: () => void
  disableSubmission: boolean
}

const StandingOrderFoooter: React.FC<Props> = ({ save, disableSubmission }) => {
  return (
    <LoadingButton
      color="primary"
      loadingIndicator={<CircularProgress color="primary" size={16} />}
      onClick={save}
      disabled={disableSubmission}
      sx={{ width: '40%' }}
      variant="contained"
    >
      Save
    </LoadingButton>
  )
}

export default StandingOrderFoooter
