import { useGetBasketLazyQuery } from '@/generated/graphql'
import { useCallback } from 'react'

export const useGetBasketQuery = () => {
  const [run, result] = useGetBasketLazyQuery()

  const error =
    result.error ||
    (result.called && !result.loading && !result.data?.basket ? 'Unknown error' : null)

  const getBasket = useCallback(
    (basketId: string) => {
      void run({ variables: { id: basketId } })
    },
    [run],
  )

  return {
    run: getBasket,
    loading: result.loading,
    basket: result.data?.basket,
    called: result.called,
    error,
  }
}
