import { Zoom, Alert, AlertProps } from '@mui/material'
import React from 'react'

interface ErrorSectionProps {
  isUpdating: boolean
  error?: React.ReactElement
  severity?: AlertProps['severity']
}

const ErrorSection: React.FC<ErrorSectionProps> = ({ error, isUpdating, severity }) => {
  if (!error) return null

  return (
    <Zoom in={!isUpdating} style={{ transitionDelay: !isUpdating ? '100ms' : '100ms' }}>
      <Alert severity={severity}>{error}</Alert>
    </Zoom>
  )
}

export default ErrorSection
