import React from 'react'
import { useMedia } from 'react-use'
import NumberPicker from '@caterdesk/ui--number-picker'
import Stack from '@caterdesk/ui--stack'
import DietaryListMui from '@/components/DietaryListMui'
import { TextExtraSmall } from '@caterdesk/ui--typography'
import { amountToPriceString, Currency } from '@caterdesk/utils--money'
import Box from '@caterdesk/ui--box'
import { mobileMediaQuery } from '@caterdesk/ui--theme'
import { SelectedOptionItem } from './menu-item-modal'
import { MenuItemOption, MenuOptionItem } from '../../../domain/menu-item-option'

type Props = {
  option: MenuItemOption
  currency: Currency
  quantity: number
  selectedOptionItems?: Map<string, SelectedOptionItem>
  selectOptionItem: (option: MenuItemOption, item: MenuOptionItem, quantity: number) => void
}

export const NumberOptions: React.FC<Props> = ({
  option,
  currency,
  quantity,
  selectedOptionItems,
  selectOptionItem,
}) => {
  const isMobileScreen = useMedia(mobileMediaQuery, false)

  const getMaximum = (itemId: string) => {
    const { maxQty, qtyPerPerson } = option

    const getMaxQty = (
      selectedOptionItems: Map<string, SelectedOptionItem>,
      maximumItemsRequired: number,
      currentQty: number,
    ) => {
      const itemQuantities = Array.from(selectedOptionItems.values()).map(
        ({ quantity }) => quantity,
      )
      const numberOfItemsSelected = itemQuantities.reduce(
        (total, itemQty) => total + (itemQty || 0),
        0,
      )

      return numberOfItemsSelected >= maximumItemsRequired ? currentQty : undefined
    }

    if (selectedOptionItems) {
      const currentQty = selectedOptionItems?.get(itemId)?.quantity || 0
      if (qtyPerPerson) {
        const maximumItemsRequired = quantity * qtyPerPerson
        return getMaxQty(selectedOptionItems, maximumItemsRequired, currentQty)
      }
      if (maxQty) {
        if (selectedOptionItems.size === maxQty) {
          return currentQty ? getMaxQty(selectedOptionItems, quantity, currentQty) : 0
        }
        return getMaxQty(selectedOptionItems, quantity, currentQty)
      }
    }
  }

  return (
    <>
      {option.items.map((item) => {
        const priceToShow = item.priceCustomerFacingExTax
        return (
          <Box borderBottom key={item._id}>
            <Box padding={{ x: isMobileScreen ? 16 : 24, y: 16 }}>
              <TextExtraSmall style={{ lineHeight: '24px', marginBottom: '6px' }}>
                {item.item.name}
              </TextExtraSmall>
              <Stack spacing={8} justifyContent="space-between">
                <Stack spacing={8} divider>
                  <NumberPicker
                    size="small"
                    border={false}
                    onChange={(value) => selectOptionItem(option, item, value)}
                    value={selectedOptionItems?.get(item._id)?.quantity || 0}
                    max={getMaximum(item._id)}
                  />
                  <DietaryListMui maxToShow={3} dietaries={item.item.dietaries} allergens={[]} />
                  <DietaryListMui
                    maxToShow={3}
                    dietaries={[]}
                    allergens={item.item.allergens}
                    subAllergens={item.item.subAllergens}
                  />
                </Stack>
                {typeof priceToShow === 'number' && (
                  <TextExtraSmall style={{ textAlign: 'right' }}>
                    {amountToPriceString(currency, priceToShow, true)}
                  </TextExtraSmall>
                )}
              </Stack>
            </Box>
          </Box>
        )
      })}
    </>
  )
}
