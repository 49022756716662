import { graphql } from '@/generated/gql'
import { useMutation } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCreateBasketMutation } from '../menu-page/create-basket-mutation'
import { useAuthenticatedUserBasketSelection } from './authenticated'
import { useUnauthenticatedUserBasketSelection } from './unauthenticated'
import { useTaskQueue } from '@/hooks/useTaskQueue'

const deleteBasketMutation = graphql(`
  mutation BasketSelection_DeleteBasket($basketId: ID!, $ordered: Boolean!) {
    deleteGmBasket(basketId: $basketId, ordered: $ordered) {
      id
    }
  }
`)

export type SelectorBasket = {
  id: string
  friendlyId?: string | null
  customName?: string | null
}

type Props = {
  isAuthenticatedUser: boolean
  vendorId?: string
  initialSelectedId?: string
  preventAutomaticSelection?: boolean
}

export const useBasketSelection = ({
  isAuthenticatedUser,
  vendorId,
  initialSelectedId,
  preventAutomaticSelection,
}: Props) => {
  const createBasketMutation = useCreateBasketMutation()
  const [selectedBasket, setSelectedBasket] = useState<SelectorBasket | null>(
    initialSelectedId ? { id: initialSelectedId } : null,
  )
  const [orderedUnselectedBaskets, setOrderedUnselectedBaskets] = useState<Array<SelectorBasket>>(
    [],
  )
  const [deletedBaskets, setDeletedBaskets] = useState<Array<string>>([])
  const [runDelete] = useMutation(deleteBasketMutation)
  const [loading, setLoading] = useState(true)
  const [creating, setCreating] = useState(false)
  const [justCreated, setBasketJustCreated] = useState(false)

  const deleteTaskQueue = useTaskQueue({ shouldProcess: true })

  const authenticatedBasketSelection = useAuthenticatedUserBasketSelection({
    vendorId: isAuthenticatedUser ? vendorId : undefined,
  })

  const { update: updateUnauthenticatedUserBasket, ...unauthenticatedUserBasketSelection } =
    useUnauthenticatedUserBasketSelection({
      vendorId: !isAuthenticatedUser ? vendorId : undefined,
    })

  const onDelete = isAuthenticatedUser
    ? authenticatedBasketSelection.onDelete
    : unauthenticatedUserBasketSelection.onDelete

  const onCreate = isAuthenticatedUser
    ? authenticatedBasketSelection.onCreate
    : unauthenticatedUserBasketSelection.onCreate

  const onUpdate = useCallback(
    (updateData: SelectorBasket) => {
      if (isAuthenticatedUser) return
      updateUnauthenticatedUserBasket(updateData)
    },
    [isAuthenticatedUser, updateUnauthenticatedUserBasket],
  )

  const baskets = isAuthenticatedUser
    ? authenticatedBasketSelection.baskets
    : unauthenticatedUserBasketSelection.baskets

  const basketsWithoutDeleted = useMemo(() => {
    return baskets.filter((basket) => !deletedBaskets.includes(basket.id))
  }, [deletedBaskets, baskets])

  const unselectedBaskets: Array<SelectorBasket> = useMemo(() => {
    if (loading) return []
    const filteredBaskets = basketsWithoutDeleted.filter(
      (basket) => basket.id !== selectedBasket?.id,
    )
    return filteredBaskets
  }, [basketsWithoutDeleted, selectedBasket?.id, loading])

  const handleSelectBasket = (basketId: string | null) => {
    const basketData =
      typeof basketId === 'string'
        ? (basketsWithoutDeleted.find((basket) => basket.id === basketId) ?? { id: basketId })
        : null
    setBasketJustCreated(false)
    setSelectedBasket(basketData)
  }

  const deleteBasket = (basketId: string) => {
    if (deletedBaskets.includes(basketId)) return
    setDeletedBaskets((prev) => [...prev, basketId])
    if (selectedBasket?.id === basketId) {
      const nextAvailableBasket = basketsWithoutDeleted
        .filter((basket) => basket.id !== basketId)
        .pop()
      setSelectedBasket(nextAvailableBasket ?? null)
    }
    deleteTaskQueue.addTask(async () => {
      const result = await runDelete({ variables: { basketId, ordered: false } })
      if (result.errors) {
        setDeletedBaskets((prev) => {
          return prev.filter((deletedBasketId) => deletedBasketId !== basketId)
        })
      } else onDelete(basketId)
    })
  }

  const createBasket = (input: Parameters<typeof createBasketMutation.run>[0]) => {
    if (createBasketMutation.loading || loading) return
    setCreating(true)
    setSelectedBasket(null)
    setBasketJustCreated(true)
    createBasketMutation.run({
      ...input,
      onCompleted: (newBasketData) => {
        const newBasket = {
          id: newBasketData.basket.id,
          friendlyId: newBasketData.basket.friendlyId,
        }
        setSelectedBasket(newBasket)
        onCreate(newBasket)
        setCreating(false)
      },
    })
  }

  const onBasketFetchSuccess = useCallback(
    (basketData: SelectorBasket) => {
      onUpdate(basketData)
    },
    [onUpdate],
  )

  const onBasketFetchError = useCallback(
    (basketId: string) => {
      onDelete(basketId)
    },
    [onDelete],
  )

  const setBasket = useCallback((basketData: SelectorBasket | null) => {
    setSelectedBasket(basketData)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (
      createBasketMutation.loading ||
      (createBasketMutation.basket?.id && selectedBasket?.id === createBasketMutation.basket.id) ||
      selectedBasket?.id === initialSelectedId ||
      basketsWithoutDeleted.length === 0 ||
      (!selectedBasket?.id && preventAutomaticSelection)
    )
      return
    const basketData =
      basketsWithoutDeleted.find((basket) => basket.id === selectedBasket?.id) ??
      basketsWithoutDeleted.find((basket) => !deletedBaskets.includes(basket.id))
    if (!basketData) return
    setBasket(basketData)
  }, [
    basketsWithoutDeleted,
    selectedBasket?.id,
    createBasketMutation.loading,
    deletedBaskets,
    createBasketMutation.basket?.id,
    initialSelectedId,
    preventAutomaticSelection,
    setBasket,
  ])

  useEffect(() => {
    if (!selectedBasket?.id) return
    if (deletedBaskets.includes(selectedBasket?.id)) setBasket(null)
  }, [deletedBaskets, selectedBasket?.id, setBasket])

  useEffect(() => {
    if (createBasketMutation.error) {
      setLoading(false)
    }
  }, [createBasketMutation.error])

  useEffect(() => {
    if (!initialSelectedId) return
    setBasket({ id: initialSelectedId })
  }, [initialSelectedId, setBasket])

  useEffect(() => {
    if (unauthenticatedUserBasketSelection.called) {
      setLoading(false)
    }
  }, [unauthenticatedUserBasketSelection.called])

  useEffect(() => {
    if (authenticatedBasketSelection.called && !authenticatedBasketSelection.loading)
      setLoading(false)
  }, [authenticatedBasketSelection.called, authenticatedBasketSelection.loading])

  useEffect(() => {
    setOrderedUnselectedBaskets((prev) => {
      const updatedExistingBaskets = prev.reduce<Array<SelectorBasket>>((acc, basket) => {
        const unselectedBasketData = unselectedBaskets.find(
          (unselectedBasket) => basket.id === unselectedBasket.id,
        )
        if (!unselectedBasketData) return acc
        return [...acc, unselectedBasketData]
      }, [])
      const newBaskets = unselectedBaskets.reduce<Array<SelectorBasket>>((acc, basket) => {
        const existingBasketData = updatedExistingBaskets.some(
          (existingBasket) => existingBasket.id === basket.id,
        )
        if (existingBasketData) return acc
        return [...acc, basket]
      }, [])
      return [...updatedExistingBaskets, ...newBaskets]
    })
  }, [unselectedBaskets])

  return {
    unselectedBaskets: orderedUnselectedBaskets,
    loading: loading || creating,
    selectedBasket,
    called: authenticatedBasketSelection.called || unauthenticatedUserBasketSelection.called,
    justCreated,
    onBasketFetchSuccess,
    onBasketFetchError,
    deleteBasket,
    createBasket,
    setSelectedBasket: handleSelectBasket,
  }
}
