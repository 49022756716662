/* eslint-disable camelcase */
import { amountToPriceString, getCurrencySymbol } from '@caterdesk/utils--money'
import { BasketFragment, GmMenuStandingOrderDataFragment } from '@/generated/graphql'
import { CustomerFacingExistingGmOrderItem } from '../states/useExistingOrder/useExistingOrderItems'
import { FragmentType, graphql, useFragment } from '@/generated/gql'
import { match, P } from 'ts-pattern'
import { numberFormat } from '@/helpers/numbers'
import type { OrderBasketMapping_OrderDataFragmentFragment } from '@/generated/gql/graphql'
import type { ConsolidatedBasketProps } from '.'
import { useLocale } from '@/components/Link'
import { validateBasketItemUpdate } from '@caterdesk/utils--gm-validation'
import allCountries from '@/helpers/countries'

const orderDataFragment = graphql(`
  fragment OrderBasketMapping_OrderDataFragment on GmOrder {
    id
    orderId
    orderItems {
      items {
        id
        name
        priceExTax
        qty
        note
        orderItemOptions {
          name
          priceExTax
        }
      }
    }
    headCount
    subtotalExTax
    orderTotalBreakdown {
      taxRateTotals {
        rate
        totalExTax
      }
      totalExTax
    }
    standingOrder {
      friendlyId
    }
    fees {
      code
      totalExTax
      description
    }
  }
`)

type Mode = 'basket' | 'standingorder' | 'placedorder'

type BasketDataRequired = Pick<
  BasketFragment,
  | 'id'
  | 'items'
  | 'customItems'
  | 'fees'
  | 'basketTotalBreakdown'
  | 'subtotalBreakdown'
  | 'settings'
  | 'friendlyId'
>

type MatchInput = {
  basketData?: BasketDataRequired | null
  orderData?: OrderBasketMapping_OrderDataFragmentFragment | null
  standingOrderData?: GmMenuStandingOrderDataFragment | null
}

type GrandTotals = {
  totalIncTax?: string
  totalTax?: string
}

const calculateAndFormatPrice = (
  amount: string | number,
  quantity: number,
  locale: string,
  prependCurrencySymbol?: boolean,
): string => {
  const showPennies = true
  const defaultCurrency = 'GBP'
  const sanitisedAmount = Number(amount)

  const currency = locale
    ? allCountries.find((country) => country.locales.includes(locale))?.currency || defaultCurrency
    : defaultCurrency

  const currencySymbol = getCurrencySymbol(currency)
  const totalAmount = quantity > 0 ? sanitisedAmount * quantity : sanitisedAmount

  return prependCurrencySymbol
    ? `${currencySymbol}${numberFormat(totalAmount || 0)}`
    : amountToPriceString(currency, totalAmount || 0, showPennies)
}

export const useMappedBasketOrOrderDataToCartData = (input: {
  basketData?: BasketDataRequired | null
  orderData: {
    rawData: FragmentType<typeof orderDataFragment> | null
    grandTotals: GrandTotals
    itemsState: CustomerFacingExistingGmOrderItem[]
  }
  standingOrderData?: GmMenuStandingOrderDataFragment | null
}): {
  mode: Mode
} & Pick<ConsolidatedBasketProps, 'items' | 'fees' | 'taxRates' | 'summary' | 'id'> => {
  const { basketData, standingOrderData } = input
  const orderData = useFragment(orderDataFragment, input.orderData.rawData)

  const locale = useLocale() ?? ''

  const mode = match<MatchInput, Mode>({
    basketData,
    orderData,
  })
    .with(
      {
        orderData: P.not(P.nullish),
      },
      () => 'placedorder',
    )
    .with(
      {
        basketData: P.not(P.nullish),
      },
      () => 'basket',
    )
    .otherwise(() => 'standingorder')

  const items = match<MatchInput, ConsolidatedBasketProps['items']>({
    basketData,
    orderData,
  })
    .with(
      {
        orderData: P.not(P.nullish),
      },
      () =>
        input.orderData.itemsState.map((item) => {
          return {
            id: item.id,
            minimumQuantity: 1,
            name: item.name,
            note: item.note ?? '',
            price: calculateAndFormatPrice(item.priceExTax, item.qty, locale, true),
            quantity: item.qty,
            canDelete: !item.isCustom,
            canUpdateQty: !item.isCustom,
            itemOptions:
              item.options.map((itemOption) => {
                return {
                  name: itemOption.name,
                  priceExTax: calculateAndFormatPrice(
                    itemOption.priceExTax,
                    itemOption.qty,
                    locale,
                    true,
                  ),
                }
              }) ?? [],
          }
        }),
    )
    .otherwise((data) => {
      const items =
        data.basketData?.items.map((item) => ({
          id: item.itemReference,
          minimumQuantity: item.item?.variations?.[0]?.minQty || 0,
          name: item.item?.name || '',
          note: item.note ?? '',
          price: calculateAndFormatPrice(
            item.item?.variations?.[0]?.priceCustomerFacingExTax ?? '',
            item.qty,
            locale,
          ),
          canDelete: true,
          canUpdateQty: true,
          quantity: item.qty,
          acknowledgedAt: item.acknowledgedAt,
          updatedAt: item.item?.updatedAt,
          itemOptions: item.options.map((itemOption) => {
            return {
              name: itemOption.item?.name ?? '',
              priceExTax: calculateAndFormatPrice(
                itemOption.optionItem?.priceCustomerFacingExTax ?? '',
                itemOption.qty,
                locale,
              ),
            }
          }),
          updatedByVendor:
            validateBasketItemUpdate({
              item: { ...item, updatedAt: item.item?.updatedAt ?? '' },
              acknowledgedAt: item.acknowledgedAt ?? null,
              options: item.options.map((_) => ({
                item: { updatedAt: item.item?.updatedAt ?? '' },
              })),
            }) || undefined,
        })) ?? []

      const customItems =
        data.basketData?.customItems.map((item) => ({
          id: item.id,
          minimumQuantity: 0,
          name: item.name,
          note: item.notes ?? '',
          price: calculateAndFormatPrice(item.customerPriceExTax, item.quantity, locale),
          quantity: item.quantity,
          acknowledgedAt: '',
          updatedAt: '',
          itemOptions: [],
          canDelete: false,
          canUpdateQty: false,
          updatedByVendor: false,
        })) ?? []

      return [...items, ...customItems]
    })

  const fees = match<MatchInput, ConsolidatedBasketProps['fees']>({
    basketData,
    orderData,
  })
    .with({ orderData: P.not(P.nullish) }, (data) =>
      data.orderData?.fees
        .filter((fee) => Number(fee.totalExTax) !== 0)
        .map((fee) => {
          return {
            code: fee.code,
            description: fee.description,
            feeTotalExTax: calculateAndFormatPrice(fee.totalExTax, 0, locale),
          }
        }),
    )
    .otherwise(
      (data) =>
        data.basketData?.fees
          .filter((fee) => Number(fee.totalExTax) !== 0)
          .map((fee) => ({
            code: fee.code,
            description: fee.description,
            feeTotalExTax: calculateAndFormatPrice(fee.totalExTax, 0, locale),
          })) ?? [],
    )
  const taxRates = match<MatchInput, ConsolidatedBasketProps['taxRates']>({
    basketData,
    orderData,
  })
    .with({ orderData: P.not(P.nullish) }, (data) =>
      data.orderData?.orderTotalBreakdown.taxRateTotals.map((tax) => {
        return {
          rate: numberFormat(tax.rate * 100),
          totalExTax: calculateAndFormatPrice(tax.totalExTax, 0, locale),
        }
      }),
    )
    .otherwise(
      (data) =>
        data.basketData?.basketTotalBreakdown?.taxRateTotals?.map((tax) => ({
          rate: numberFormat(tax.rate * 100),
          totalExTax: calculateAndFormatPrice(tax.totalExTax, 0, locale),
        })) ?? [],
    )

  const itemCount = items.reduce((total, item) => total + item.quantity, 0)
  const commonSummaryProperties = {
    charityContributionHeadcount:
      orderData?.headCount ?? basketData?.settings.headCount.toString() ?? '',
    friendlyId: standingOrderData?.name ?? basketData?.friendlyId ?? orderData?.orderId ?? '',
    subtotalExTax: calculateAndFormatPrice(
      Number(orderData?.subtotalExTax ?? basketData?.subtotalBreakdown.subtotalExTax),
      0,
      locale,
    ),
    totalExTax: calculateAndFormatPrice(
      Number(
        orderData?.orderTotalBreakdown.totalExTax ?? basketData?.basketTotalBreakdown.totalExTax,
      ),
      0,
      locale,
    ),
    totalIncTax: calculateAndFormatPrice(
      Number(input.orderData.grandTotals.totalIncTax),
      0,
      locale,
      true,
    ),
    itemCount,
  }

  const summary: ConsolidatedBasketProps['summary'] =
    mode === 'placedorder'
      ? {
          ...commonSummaryProperties,
          totalTaxValue: calculateAndFormatPrice(
            Number(input.orderData.grandTotals.totalTax),
            0,
            locale,
            true,
          ),
          grandTotal: calculateAndFormatPrice(
            Number(input.orderData.grandTotals.totalIncTax),
            0,
            locale,
            true,
          ),
        }
      : {
          ...commonSummaryProperties,
          totalTaxValue: calculateAndFormatPrice(
            Number(basketData?.basketTotalBreakdown.taxValue),
            0,
            locale,
          ),
          grandTotal: calculateAndFormatPrice(
            Number(basketData?.basketTotalBreakdown.totalIncTax),
            0,
            locale,
          ),
        }

  const id = match<MatchInput>({
    basketData,
    orderData,
    standingOrderData,
  })
    .with({ orderData: P.not(P.nullish) }, ({ orderData }) => orderData.id)
    .with(
      { basketData: P.not(P.nullish), standingOrderData: P.nullish },
      ({ basketData }) => basketData.id,
    )
    .with({ standingOrderData: P.not(P.nullish) }, ({ standingOrderData }) => standingOrderData._id)
    .otherwise(() => '')

  return { items, mode, fees, summary, taxRates, id }
}
