import { match } from 'ts-pattern'

const generateLink = (type: 'basket' | 'standingOrder' | 'order', id: string) => {
  const param = match(type)
    .with('order', () => 'orderId')
    .with('basket', () => 'id')
    .with('standingOrder', () => 'standingOrderId')
    .exhaustive()
  return `${window.location.origin}${window.location.pathname}?${param}=${id}`
}

export const generateBasketLink = (basketId: string) => generateLink('basket', basketId)

export const generateStandingOrderLink = (standingOrderId: string) =>
  generateLink('standingOrder', standingOrderId)

export const generateOrderLink = (orderId: string) => generateLink('order', orderId)
