import React from 'react'
import { MenuFilterCategory, SelectedMenuFilterCategory } from '../../domain/menu-category-filter'
import { Tab, Tabs } from '@mui/material'
import useGmFiltersStore from '../../helpers/gmFiltersStore'

type Props = {
  categories: MenuFilterCategory[]
  selectedCategory: SelectedMenuFilterCategory
}

const CategoryTabs: React.FC<Props> = ({ categories, selectedCategory }) => {
  const { setSelectedCategory, unsetSelectedCategory } = useGmFiltersStore()

  const handleCategoryTabChange = (_event: React.SyntheticEvent, newCategoryId: string) => {
    const selectedCategoryToSet = categories.find((category) => category.id === newCategoryId)
    if (!selectedCategoryToSet) return
    setSelectedCategory(selectedCategoryToSet)
  }

  return (
    <Tabs
      value={selectedCategory.id}
      onChange={handleCategoryTabChange}
      variant="scrollable"
      scrollButtons="auto"
      textColor="primary"
      allowScrollButtonsMobile
      aria-label="Category Tabs"
    >
      {categories
        .filter((category) => !category.name.includes('dishes'))
        .map((category) => (
          <Tab
            key={category.id}
            label={category.name}
            value={category.id}
            sx={{ color: 'primary.main' }}
            aria-label={`Select ${category.name}`}
            onClick={() => {
              if (category.id === selectedCategory.id) {
                unsetSelectedCategory()
              }
            }}
          />
        ))}
    </Tabs>
  )
}

export default CategoryTabs
