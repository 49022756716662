import { useCallback, useEffect, useMemo, useState } from 'react'
import { createMenuAvailability, MenuAvailability } from '../../domain/menu-availability'
import { SelectedMenuFilterCategory } from '../../domain/menu-category-filter'
import { MenuItemFilterSections } from '../../domain/menu-item-filter'
import { MenuVendorCategory } from '../../domain/menu-vendor'
import { useAppError } from '../../utils/errors'
import { useAppState } from './app'
import { GetVendorAvailabilityQuery, useGetVendorAvailabilityLazyQuery } from '@/generated/graphql'
import useGmFiltersStore from '../../helpers/gmFiltersStore'
import { AuthState } from './auth'
import { getDateTimeObject } from '../../domain/user-basket-settings'

export type LoadedMenu = {
  type: 'loaded'
  availability: MenuAvailability
  hasMore: boolean
  reloading: boolean
  loadMore: () => void
  loadingMore: boolean
}

type PageState = { type: 'loading' } | LoadedMenu

export const useMenuPageState = ({
  itemData,
  useServerSideItems,
  vendor,
}: {
  itemData?: GetVendorAvailabilityQuery
  useServerSideItems?: boolean
  authState: AuthState
  vendor: { id: string; permalink: string; categories: MenuVendorCategory[] }
}): PageState => {
  const { throwAppError } = useAppError()
  const { userState } = useAppState()

  const { itemName, selectedCategory, goals, allergens, dietaries } = useGmFiltersStore()

  const [runGetAvailability, getAvailabilityResult] = useGetVendorAvailabilityLazyQuery()
  const rawAvailability =
    useServerSideItems && itemData
      ? itemData.availability
      : (getAvailabilityResult.data?.availability ??
        getAvailabilityResult.previousData?.availability)

  const availability = useMemo(() => {
    return rawAvailability
      ? createMenuAvailability({
          availability: { ...rawAvailability, itemsPagedv2: rawAvailability?.itemsPagedv2 || [] },
          vendorCategories: vendor.categories,
          selectedCategory,
          useServerSideItems,
          filters: {
            itemName,
            goals,
            dietaries,
            allergens,
          },
        })
      : null
  }, [rawAvailability, selectedCategory, itemName, goals, dietaries, allergens])

  const [loadingMore, setLoadingMore] = useState(false)

  const loadMore = useCallback(async () => {
    if (useServerSideItems) return
    setLoadingMore(true)
    await getAvailabilityResult.fetchMore({
      variables: {
        offset: availability?.items.length,
        limit: 20,
      },
    })
    setLoadingMore(false)
  }, [getAvailabilityResult, availability?.items.length])

  const constructFiltersForQuery = useCallback(
    (filters: MenuItemFilterSections, category: SelectedMenuFilterCategory) => {
      const selectedDietaries = [
        ...filters.goals.map((goal) => goal.filterInputValue),
        ...filters.dietaries.reduce<string[]>((dietaries, dietary) => {
          if (dietary.filterArray !== 'dietaries') return dietaries
          return [...dietaries, dietary.filterInputValue]
        }, []),
      ]
      const selectedAllergens = [
        ...filters.allergens.map((allergen) => allergen.value),
        ...filters.dietaries.reduce<string[]>((dietaries, dietary) => {
          if (dietary.filterArray !== 'allergens') return dietaries
          return [...dietaries, dietary.filterInputValue]
        }, []),
      ]
      const categoryId = Number(category.id)
      const dateTimeObject = getDateTimeObject({ date: userState.date, time: userState.time })
      return {
        allergens: selectedAllergens,
        dietaries: selectedDietaries,
        ...(!Number.isNaN(categoryId) && { categoryId: [categoryId] }),
        ...(category.id === 'hot_dishes' && { isHot: true }),
        ...(category.id === 'cold_dishes' && { isHot: false }),
        name: itemName,
        ...(dateTimeObject && {
          deliveryDate: { ...dateTimeObject, timezone: 'Europe/London' },
        }),
      }
    },
    [itemName, userState.date, userState.time],
  )

  useEffect(() => {
    if (useServerSideItems) return
    const sortedCategoryIds = [...vendor.categories]
      .sort((a, b) => {
        if (a.position === null) return 1
        if (b.position === null) return -1
        return a.position - b.position
      })
      .map((category) => Number(category.id))

    const constructedFilters = constructFiltersForQuery(
      {
        goals,
        allergens,
        dietaries,
      },
      selectedCategory,
    )
    void runGetAvailability({
      variables: {
        vendorId: vendor.id,
        filters: constructedFilters,
        sorting: { categorySortPriority: sortedCategoryIds },
        offset: 0,
        limit: 20,
      },
    })
  }, [
    vendor,
    goals,
    allergens,
    dietaries,
    selectedCategory,
    runGetAvailability,
    constructFiltersForQuery,
    useServerSideItems,
  ])

  const initialPageState: PageState = {
    type: 'loading',
  }
  if (
    !useServerSideItems &&
    (!getAvailabilityResult.called ||
      (getAvailabilityResult.loading && !getAvailabilityResult?.previousData))
  ) {
    return initialPageState
  }

  if (!availability)
    return throwAppError({
      type: 'COULD_NOT_FETCH_AVAILABILITY_FOR_VENDOR',
      vendorId: vendor.id,
      cause: getAvailabilityResult.error,
    })

  return {
    ...initialPageState,
    type: 'loaded',
    reloading: getAvailabilityResult.loading,
    availability,
    hasMore: Boolean(getAvailabilityResult.data?.availability?.itemsPagedv2.pageInfo.hasMore),
    loadMore,
    loadingMore,
  }
}
