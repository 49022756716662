import { Box, CircularProgress, Divider, Stack, Tooltip, Typography } from '@mui/material'
import BasketItem from './basket-item'
import InfoIcon from '@mui/icons-material/Info'
import type React from 'react'
import useCharityMeals from '@/helpers/features/charityMeals'

type ItemOption = {
  name: string
  priceExTax: string
}
export type Item = {
  id: string
  itemOptions: ItemOption[]
  minimumQuantity: number
  name: string
  note: string
  price: string
  quantity: number
  acknowledgedAt?: string
  updatedAt?: string
  updatedByVendor?: boolean
  canUpdateQty: boolean
  canDelete: boolean
}

type Items = Array<Item>

export type SummaryData = {
  itemCount: number
  charityContributionHeadcount: string
  friendlyId: string
  subtotalExTax: string
  totalTaxValue?: string
  totalExTax?: string
  totalIncTax?: string
  grandTotal: string
}

type Fee = {
  code: string
  description: string
  feeTotalExTax: string
}

type Fees = Array<Fee>

type Tax = {
  rate: string
  totalExTax: string
}

type TaxRates = Array<Tax>

export type ConsolidatedBasketProps = {
  id: string
  fees: Fees
  taxRates: TaxRates
  headerSection: React.ReactElement
  footerSection: React.ReactElement
  errorSection: React.ReactElement
  items: Items
  summary: SummaryData
  taxAcronym: string
  dataIsUpdating: boolean
  loadingNewData?: boolean
  maxHeight: string
  onDeleteClick: (itemId: string) => void
  onQuantityChange: (itemId: string, qty: number) => void
}

const ConsolidatedBasket: React.FC<ConsolidatedBasketProps> = ({
  fees,
  taxRates,
  headerSection,
  footerSection,
  errorSection,
  items,
  summary,
  taxAcronym,
  dataIsUpdating,
  loadingNewData = false,
  maxHeight,
  onDeleteClick,
  onQuantityChange,
}) => {
  const isEmptyBasket = items.length < 1
  const charityMealsEnabled = useCharityMeals()

  return (
    <Stack bgcolor="background.paper" borderRadius={1} sx={{ maxHeight }} spacing={1}>
      {headerSection}
      <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
        {loadingNewData ? (
          <Box
            sx={{ paddingX: 2, paddingBottom: 2, height: '150px' }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : null}
        {isEmptyBasket && !loadingNewData ? (
          <Box
            sx={{ paddingX: 2, paddingBottom: 2, height: '150px' }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body2" align="center">
              Your basket is empty. Add some items to get started.
            </Typography>
          </Box>
        ) : null}
        {!isEmptyBasket && !loadingNewData ? (
          <>
            <Box sx={{ paddingX: 2, flexGrow: 1 }}>
              {items.map((item: Item) => {
                return (
                  <BasketItem
                    key={item.id}
                    onDeleteClick={onDeleteClick}
                    onQuantityChange={(_itemReference, qty) => onQuantityChange(item.id, qty)}
                    {...item}
                    canDelete={item.canDelete}
                    canUpdateQty={item.canUpdateQty}
                  />
                )
              })}
            </Box>
            <Stack spacing={1.5} paddingX={2} paddingBottom={2}>
              <Stack direction="column" justifyContent="space-between">
                {taxRates?.map((tax) => {
                  return (
                    <Stack direction="row" justifyContent="space-between" key={tax.rate}>
                      <Typography variant="caption">
                        Item subtotal ({taxAcronym} @ {tax.rate}%)
                      </Typography>
                      <Typography variant="body2">
                        {dataIsUpdating ? <CircularProgress size={16} /> : `${tax.totalExTax}`}
                      </Typography>
                    </Stack>
                  )
                })}
                {fees?.map((fee) => {
                  const convertedTotal = fee.feeTotalExTax
                  return (
                    <Stack direction="row" justifyContent="space-between" key={fee.code}>
                      <Typography variant="caption">{fee.description}</Typography>
                      <Typography variant="body2">
                        {dataIsUpdating ? <CircularProgress size={16} /> : `${convertedTotal}`}
                      </Typography>
                    </Stack>
                  )
                })}
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="caption">Total {taxAcronym}</Typography>
                  <Typography variant="body2">
                    {dataIsUpdating ? <CircularProgress size={16} /> : `${summary?.totalTaxValue}`}
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              {charityMealsEnabled.enabled && (
                <Stack direction="row" justifyContent="space-between" alignItems={'center'}>
                  <Typography variant="caption">
                    ❤️ Since you&apos;re feeding {summary?.charityContributionHeadcount}, we&apos;ll
                    feed {summary?.charityContributionHeadcount} as well.
                  </Typography>
                  <Tooltip
                    title="For every meal you order with Feedr, we donate a nutritious meal to a school child in rural India, in partnership with the Akshaya Patra Foundation."
                    placement="left"
                  >
                    <InfoIcon color="info" sx={{ transform: 'scale(0.75)' }} />
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          </>
        ) : null}
      </Box>
      {!isEmptyBasket ? (
        <>
          <Box paddingX={2}>{errorSection}</Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={'center'}
            paddingX={2}
            paddingY={1}
            spacing={2}
          >
            {footerSection}
            <Stack direction={'column'} flexGrow={1} alignItems={'flex-end'}>
              <Stack direction={'row'} spacing={1} alignItems={'baseline'}>
                <Typography variant="h5">Total</Typography>
                <Typography variant="caption">(inc {taxAcronym})</Typography>
              </Stack>
              <Typography variant="h5">
                {dataIsUpdating ? <CircularProgress size={24} /> : `${summary.grandTotal}`}
              </Typography>
            </Stack>
          </Stack>
        </>
      ) : null}
    </Stack>
  )
}

export default ConsolidatedBasket
